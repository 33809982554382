import React, { useState, useCallback, useEffect } from "react";
import {
  View,
  Modal,
  TouchableOpacity,
  ActivityIndicator,
  ScrollView,
  Image,
  Dimensions,
} from "react-native";
import Text from "./Text";
import TextInput from "./TextInput";
import Ionicons from "@expo/vector-icons/Ionicons";
import { debounce } from "lodash";
import axios from "axios";

export default function GiphyPicker({ show, close, pick }) {
  const [q, setQ] = useState("");
  const [ajax, setAjax] = useState(false);
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (show) {
      axios
        .get("https://api.giphy.com/v1/gifs/trending?api_key=ffbDoRDHgomTS", {
          transformRequest: (data, headers) => {
            delete headers.uid;
            return data;
          },
        })
        .then((d) => {
          console.log(d.data.data);
          setResults(d.data.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [show]);

  const changeQ = (t) => {
    setQ(t);
    if (t.length > 2) {
      setAjax(true);
      handler(t);
    } else {
      setAjax(false);
      setResults([]);
    }
  };

  const search = (t) => {
    // console.log("PERFORM SEARCH", t);
    axios
      .get(
        `https://api.giphy.com/v1/gifs/search?q=${t}&api_key=ffbDoRDHgomTS`,
        {
          transformRequest: (data, headers) => {
            delete headers.uid;
            return data;
          },
        }
      )
      .then((d) => {
        setResults(d.data.data);
        setAjax(false);
        // console.log(d.data.data);
      });
  };

  const handler = useCallback(debounce(search, 500), []);

  const _close = () => {
    setAjax(false);
    setResults([]);
    setQ("");
    close();
  };

  const _pick = (g) => {
    // console.log("PICK", g.images.original);
    pick(
      g.images.original.url,
      g.images.original.width,
      g.images.original.height
    );
    _close();
  };

  return (
    <Modal visible={show} presentationStyle="formSheet" animationType="slide">
      <View style={{ flex: 1 }}>
        <View
          style={{
            flexDirection: "row",
            padding: 10,
            alignItems: "center",
          }}
        >
          <TextInput
            style={{ fontSize: 16, flex: 1, marginRight: 10 }}
            placeholder="Search Giphy..."
            value={q}
            onChangeText={(e) => changeQ(e)}
          />
          <TouchableOpacity
            onPress={() => {
              _close();
            }}
          >
            <Ionicons name="close" size={28} />
          </TouchableOpacity>
        </View>
        {ajax ? <ActivityIndicator style={{ marginBottom: 1 }} /> : null}
        <ScrollView style={{ flex: 1 }}>
          <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
            {results.map((r) => (
              <TouchableOpacity key={r.id} onPress={() => _pick(r)}>
                <Image
                  source={{ uri: r.images.fixed_width_small.url }}
                  style={{
                    width: Dimensions.get("screen").width / 2 - 4,
                    height: Dimensions.get("screen").width / 2 - 4,
                    margin: 2,
                  }}
                />
              </TouchableOpacity>
            ))}
          </View>
        </ScrollView>
      </View>
    </Modal>
  );
}
