import React from "react";
import { TextPropTypes, Text } from "react-native";
import PropTypes from "prop-types";
import { useColors } from "../store";

export default function T({ style, children }) {
  const { colors } = useColors();
  return (
    <Text
      style={[
        { color: colors.text, fontFamily: "Lato_400Regular" },
        { ...style },
      ]}
    >
      {children}
    </Text>
  );
}

T.propTypes = { ...TextPropTypes };
