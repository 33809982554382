import React from "react";

export default function Slider({ signUp }) {
  return (
    <div>
      <h2>Here goes introduction for the web version of app.</h2>
      <button onClick={signUp}>Open Login</button>
    </div>
  );
}
