import React from "react";
import Portal from "./Portal";

export default function ImageView(props) {
  return (
    <Portal>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "#000",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <a
            style={{
              color: "rgb(255, 255, 255)",
              fontFamily: "Lato_400Regular",
              position: "absolute",
              top: 10,
              right: 10,
              cursor: "pointer",
            }}
            onClick={() => props.onRequestClose()}
          >
            Close
          </a>
          <img src={props.images[props.imageIndex].uri} width="100%" />
        </div>
      </div>
    </Portal>
  );
}
