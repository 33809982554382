import React, { useState, useEffect } from "react";
import { View, Modal, Pressable, Dimensions } from "react-native";
import { useContacts, useSocket } from "../store";
import { RTCView } from "react-native-webrtc-web-shim";
import Text from "../components/Text";
import Avatar from "../components/Avatar";
import InCallManager from "../shims/InCallManager";
import Ionicons from "@expo/vector-icons/Ionicons";
import MaterialIcons from "@expo/vector-icons/MaterialCommunityIcons";
import Draggable from "react-native-draggable";

export default function CallScreen() {
  const {
    state,
    recipient,
    endCall,
    remoteStream,
    localStream,
    videoCall,
    muted,
    toggleMute,
    duration,
  } = useSocket();
  const { contacts } = useContacts();

  const [speaker, setSpeaker] = useState(false);

  useEffect(() => {
    InCallManager.setForceSpeakerphoneOn(speaker);
  }, [speaker]);

  useEffect(() => {
    setSpeaker(false);
  }, [localStream]);

  useEffect(() => {
    InCallManager.setForceSpeakerphoneOn(videoCall);
    setSpeaker(videoCall);
  }, [videoCall, state]);

  return (
    <Modal
      visible={state && state !== "incoming" ? true : false}
      animationType={"fade"}
    >
      <View style={{ backgroundColor: "#111", flex: 1 }}>
        {videoCall ? (
          <>
            {remoteStream ? (
              <RTCView
                stream={remoteStream}
                style={{ width: "100%", height: "100%", borderRadius: 10 }}
                objectFit={"cover"}
              />
            ) : null}

            {localStream ? (
              <Draggable
                minX={10}
                maxX={Dimensions.get("window").width - 10}
                minY={50}
                maxY={Dimensions.get("window").height - 50}
                x={Dimensions.get("window").width - 120}
                y={Dimensions.get("window").height - 210}
              >
                <RTCView
                  stream={localStream}
                  mirror={true}
                  style={{ width: 110, height: 160, borderRadius: 10 }}
                  objectFit={"cover"}
                />
              </Draggable>
            ) : null}
            <View
              style={{
                flexDirection: "row",
                position: "absolute",
                bottom: 50,
                left: 10,
                alignItems: "center",
              }}
            >
              <Pressable
                style={{
                  padding: 12,
                  backgroundColor: "#f00",
                  borderRadius: 10,
                  opacity: 0.7,
                  justifyContent: "center",
                }}
                onPress={() => endCall(recipient)}
              >
                <MaterialIcons name="phone-off" size={22} color="#FFF" />
              </Pressable>
              <Pressable
                onPress={() => toggleMute()}
                style={{
                  padding: 10,
                  backgroundColor: "#222",
                  borderRadius: 10,
                  alignItems: "center",
                  marginLeft: 5,
                }}
              >
                <Ionicons
                  name={muted ? "mic-off-outline" : "mic-outline"}
                  color={muted ? "#F00" : "#666"}
                  size={22}
                />
              </Pressable>
              <Pressable
                onPress={() => setSpeaker(!speaker)}
                style={{
                  padding: 10,
                  backgroundColor: "#222",
                  borderRadius: 10,
                  alignItems: "center",
                  marginLeft: 5,
                }}
              >
                <Ionicons
                  name={speaker ? "volume-high-outline" : "volume-mute-outline"}
                  color={speaker ? "#FFF" : "#666"}
                  size={22}
                />
              </Pressable>
              <Text
                style={{
                  color: "#fff",
                  marginLeft: 15,
                  opacity: state === "active" || state === "answered" ? 1 : 0,
                }}
              >
                {humanTimeFormat(duration)}
              </Text>
            </View>
          </>
        ) : (
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: 100,
            }}
          >
            <Text
              style={{
                color: "#FFF",
                fontSize: 20,
                fontFamily: "Lato_700Bold",
                marginBottom: 5,
              }}
            >
              {contacts[recipient]
                ? contacts[recipient].name
                : "Unknown contact"}
            </Text>
            <Text
              style={{
                color: "#fff",
                marginBottom: 30,
                opacity: state === "active" || state === "answered" ? 1 : 0,
              }}
            >
              {humanTimeFormat(duration)}
            </Text>
            <Avatar url={contacts[recipient]?.image} size={100} />
            {remoteStream ? <RTCView stream={remoteStream} /> : null}
            {localStream ? <RTCView stream={localStream} /> : null}

            <View style={{ margin: 30, flexDirection: "row" }}>
              <Pressable
                onPress={() => toggleMute()}
                style={{
                  padding: 20,
                  margin: 3,
                  backgroundColor: "#222",
                  borderRadius: 15,
                  alignItems: "center",
                  width: 100,
                }}
              >
                <Ionicons
                  name={muted ? "mic-off-outline" : "mic-outline"}
                  color={muted ? "#F00" : "#666"}
                  size={40}
                />
                <Text
                  style={{ color: "#FFF", textAlign: "center", marginTop: 10 }}
                >
                  {muted ? "Muted" : "Mute"}
                </Text>
              </Pressable>
              <Pressable
                onPress={() => setSpeaker(!speaker)}
                style={{
                  padding: 20,
                  margin: 3,
                  backgroundColor: "#222",
                  borderRadius: 15,
                  alignItems: "center",
                  width: 100,
                }}
              >
                <Ionicons
                  name={speaker ? "volume-high-outline" : "volume-mute-outline"}
                  color={speaker ? "#FFF" : "#666"}
                  size={40}
                />
                <Text
                  style={{ color: "#FFF", textAlign: "center", marginTop: 10 }}
                >
                  Speaker
                </Text>
              </Pressable>
            </View>
            <Pressable
              style={{
                padding: 12,
                position: "absolute",
                bottom: 50,
                left: "5%",
                backgroundColor: "#f00",
                borderRadius: 10,
                opacity: 0.7,
                width: "90%",
              }}
              onPress={() => endCall(recipient)}
            >
              <Text
                style={{
                  color: "#FFF",
                  fontSize: 16,
                  textAlign: "center",
                  fontFamily: "Lato_700Bold",
                }}
              >
                End call
              </Text>
            </Pressable>
          </View>
        )}

        {state === "calling" || state === "ringing" ? (
          <View style={{ width: "100%", position: "absolute", top: 100 }}>
            <Text
              style={{
                textAlign: "center",
                fontSize: 20,
                color: "#FFF",
                fontFamily: "Lato_700Bold",
              }}
            >
              {state === "calling" ? "Calling ..." : ""}
              {state === "ringing" ? "Ringing ..." : ""}
            </Text>
          </View>
        ) : null}
      </View>
    </Modal>
  );
}

export function humanTimeFormat(time, showUnit) {
  // Hours, minutes and seconds
  var hrs = ~~(time / 3600);
  var mins = ~~((time % 3600) / 60);
  var secs = ~~time % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";
  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }
  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;

  if (showUnit) {
    if (hrs > 0) ret = ret.slice(0, -3) + " hr";
    else ret += " min";
  }
  return ret;
}
