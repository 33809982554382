import React, { useState, useRef } from "react";
import {
  View,
  Text,
  Pressable,
  StyleSheet,
  ActivityIndicator,
} from "react-native";
import PhoneInput from "react-native-phone-number-input";
import CodeCheck from "./CodeCheck";
import { useUser } from "../store";
import axios from "axios";

const styles = StyleSheet.create({
  root: { flex: 1, padding: 20 },
  title: { textAlign: "center", fontSize: 30 },
  codeFieldRoot: { marginTop: 20 },
  cell: {
    width: 40,
    height: 40,
    lineHeight: 38,
    fontSize: 24,
    borderWidth: 2,
    borderColor: "#00000030",
    textAlign: "center",
  },
  focusCell: {
    borderColor: "#000",
  },
});

const CELL_COUNT = 6;

export default function SignUp({ cancel }) {
  const [value, setValue] = useState("");
  const [formattedValue, setFormattedValue] = useState("");
  const phoneInput = useRef();
  const [step, setStep] = useState(1);
  const [error, setError] = useState("");
  const [ajax, setAjax] = useState(false);
  const [codeValue, setCodeValue] = useState("");
  const [codeHint, setCodeHint] = useState("");

  const { logIn, setSioToken } = useUser();

  const sendSMS = () => {
    console.log(formattedValue);
    setError("");
    if (phoneInput.current?.isValidNumber(value)) {
      setAjax(true);
      axios
        .post(
          "https://signinonce.com/api/phoneLogin",
          {
            phone: formattedValue,
          },
          { headers: { portal_id: 8 } }
        )
        .then((d) => {
          // console.log(d.data);
          setStep(2);
          setAjax(false);
          setCodeHint(d.data.data);
        });
    } else {
      setError("Phone number not valid.");
    }
  };

  const checkCode = (code) => {
    setAjax(true);
    setError("");

    axios
      .post(
        "https://signinonce.com/api/checkCode",
        {
          phone: formattedValue,
          otp: code,
        },
        { headers: { portal_id: 8 } }
      )
      .then((d) => {
        console.log(d.data);
        setAjax(false);
        if (d.data.status) {
          setError("");
          logIn(d.data.data.uuid, formattedValue);
          setSioToken(d.data.data.api_token);
        } else {
          setError("You entered wrong code.");
          setCodeValue("");
        }
      })
      .catch((e) => {
        console.log(e.response);
        setError("Network error.");
        setCodeValue("");
        setAjax(false);
      });
  };

  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      {step === 1 && (
        <View style={{ alignItems: "center" }}>
          <Text style={{ marginBottom: 20 }}>Enter your phone number</Text>
          <PhoneInput
            ref={phoneInput}
            defaultValue={value}
            defaultCode="BE"
            layout="first"
            onChangeText={(text) => {
              setValue(text);
            }}
            onChangeFormattedText={(text) => {
              setFormattedValue(text);
            }}
            withDarkTheme
            withShadow
            autoFocus
            disabled={ajax}
          />
          {error ? (
            <Text
              style={{
                color: "#E33",
                fontWeight: "bold",
                margin: 20,
                textAlign: "center",
              }}
            >
              {error}
            </Text>
          ) : null}
          {ajax ? <ActivityIndicator style={{ margin: 20 }} /> : null}
          <Pressable
            style={{
              backgroundColor: "#06D",
              padding: 15,
              borderRadius: 40,
              width: 300,
              marginVertical: 30,
              opacity: ajax ? 0.5 : 1,
            }}
            onPress={() => sendSMS()}
            disabled={ajax}
            accessibilityRole="button"
          >
            <Text style={{ color: "#FFF", textAlign: "center" }}>Next</Text>
          </Pressable>
          <Pressable onPress={cancel}>
            <Text>Cancel</Text>
          </Pressable>
        </View>
      )}
      {/* {codeHint ? <Text>{codeHint}</Text> : null} */}
      {step === 2 && (
        <CodeCheck
          check={checkCode}
          ajax={ajax}
          error={error}
          value={codeValue}
          setValue={setCodeValue}
          back={() => {
            setStep(1);
            setError("");
          }}
        />
      )}
    </View>
  );
}
