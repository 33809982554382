import React from "react";
import { View, Image, ActivityIndicator } from "react-native";
import Icon from "@expo/vector-icons/Fontisto";
import axios from "axios";
import { useColors } from "../store";
import FastImage from "./FastImage";

export default function Avatar({ url, size, uploading, group }) {
  const { colors } = useColors();

  const fixUrl = (u) => {
    if (u.startsWith("uploads/")) {
      return axios.defaults.baseURL + "../" + u.split(".jpg")[0] + "_tn.jpg";
    }
    return u;
  };
  if (url)
    return (
      <View>
        <FastImage
          source={{ uri: fixUrl(url) }}
          style={{
            width: size,
            height: size,
            borderRadius: size,
            opacity: uploading ? 0.5 : 1,
          }}
        />
        {uploading ? (
          <ActivityIndicator
            style={{
              position: "absolute",
              top: size / 2 - 16,
              left: size / 2 - 16,
            }}
            color="#000"
            size="large"
          />
        ) : null}
      </View>
    );
  return (
    <View
      style={{
        backgroundColor: group ? colors.green : colors.main,
        width: size,
        height: size,
        borderRadius: size,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Icon
        name={group ? "persons" : "person"}
        size={Math.max(size - 45, 25)}
        color="#FFF"
      />
    </View>
  );
}
