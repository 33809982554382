import { Alert, Platform } from "react-native";
import swal from "sweetalert2";

export const Confirm = async (title, description) => {
  return new Promise(async (resolve, reject) => {
    if (Platform.OS === "web") {
      const { value } = await swal.fire({
        title,
        text: description,
        showCancelButton: true,
        reverseButtons: true,
        icon: "question",
      });
      if (value) resolve();
      else reject();
    } else {
      Alert.alert(
        title,
        description,
        [
          {
            text: "Cancel",
            onPress: () => reject(),
            style: "cancel",
          },
          { text: "Yes", onPress: () => resolve() },
        ],
        { cancelable: false }
      );
    }
  });
};

export const Info = (title, description) => {
  if (Platform.OS === "web") {
    swal.fire({
      title,
      text: description,
      icon: "info",
    });
  } else {
    Alert.alert(title, description, [{ text: "Ok", onPress: () => {} }]);
  }
};
