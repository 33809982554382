import React, { useEffect, useRef, useState } from "react";
import { useNavigation, useRoute } from "@react-navigation/native";
import {
  View,
  TouchableOpacity,
  DeviceEventEmitter,
  Platform,
  Modal,
  ActivityIndicator,
  Image,
  StyleSheet,
  Pressable,
  Dimensions,
  Keyboard,
} from "react-native";
import Text from "../components/Text";
import Clipboard from "@react-native-community/clipboard";
import { useColors, useContacts, useUploads, useUser } from "../store";
import {
  GiftedChat,
  Composer,
  LoadEarlier,
  Send,
} from "react-native-gifted-chat";
import Avatar from "../components/Avatar";
import firebase from "firebase/app";
import "firebase/firestore";
import Ionicons from "@expo/vector-icons/Ionicons";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import axios from "axios";
import * as DocumentPicker from "expo-document-picker";
import { v4 as uuidv4 } from "uuid";
import GiphyPicker from "../components/GiphyPicker";
import MyBubble from "../components/Bubble";
import { useActionSheet } from "@expo/react-native-action-sheet";
import ImageView from "../components/ImageView";
import { WebView } from "react-native-webview";
import { SafeAreaView } from "react-native-safe-area-context";
import dayjs from "dayjs";
import {
  MentionInput,
  replaceMentionValues,
} from "react-native-controlled-mentions";
import { fixUrl } from "./ChatDetail";

import ImagesPreSend from "../components/ImagesPreSend";
import ImagePicker from "../components/ImagePickerShim";

let keyboardShowListener;
let keyboardHideListener;

const renderAvatar = (navigation, profile) => {
  // console.log("HERE", profile);
  // return false;
  if (profile) {
    return (
      <TouchableOpacity
        onPress={() => navigation.navigate("ProfileView", { id: profile._id })}
      >
        <Avatar url={profile?.image} size={36} />
      </TouchableOpacity>
    );
  }
  return <Avatar url={null} size={36} />;
};

export default function ChatDetailGroup() {
  const route = useRoute();
  const navigation = useNavigation();
  const unsubscribe = useRef();
  const unsubscribe2 = useRef();
  const groupId = route.params.id;
  const [messages, setMessages] = useState([]);
  const [endReached, setEndReached] = useState(false);
  const [loadingEarlier, setLoadingEarlier] = useState(false);
  const { uid, user } = useUser();
  const { colors } = useColors();
  const { contacts, checkMissing } = useContacts();
  const { resizeAndUpload, upload } = useUploads();
  const [showGiphy, setShowGiphy] = useState(false);
  const [replyTo, setReplyTo] = useState(null);
  const [editMessage, setEditMessage] = useState(null);
  const [messageText, setMessageText] = useState("");
  const { showActionSheetWithOptions } = useActionSheet();
  const [chatImages, setChatImages] = useState([]);
  const [imageKey, setImageKey] = useState(-1);
  const [showFile, setShowFile] = useState(null);
  const [lastRead, setLastRead] = useState(0);
  const [groupData, setGroupData] = useState({});
  const suggestions = useRef([]);
  const [pickedImages, setPickedImages] = useState([]);
  const [paddingBottom, setPaddingBottom] = useState(0);

  useEffect(() => {
    if (route.params.name)
      navigation.setOptions({
        headerTitle: () => (
          <View style={{ alignItems: "center" }}>
            <Text style={{ color: "#FFF", fontFamily: "Lato_700Bold" }}>
              {route.params.name}
            </Text>
          </View>
        ),
        headerRight: () => (
          <TouchableOpacity
            style={{ paddingHorizontal: 16 }}
            onPress={() =>
              navigation.navigate("Group", {
                id: groupId,
              })
            }
          >
            <Ionicons name="people" size={24} color={"#FFF"} />
          </TouchableOpacity>
        ),
      });

    if (!unsubscribe.current) {
      loadAndListen();
    }
    console.log("CONTACTS", contacts);

    keyboardShowListener = Keyboard.addListener("keyboardDidShow", (event) => {
      // console.log("Keyboard did show", event.endCoordinates.height);
      setPaddingBottom(event.endCoordinates.height + 50);
    });

    keyboardHideListener = Keyboard.addListener("keyboardDidHide", () => {
      setPaddingBottom(0);
    });

    return onUnmount;
  }, []);

  useEffect(() => {
    // console.log("CONTACTS CHANGED", groupData);
    if (groupData.participants) {
      setScreenTitle(groupData);
      // messages.forEach((m) => {
      //   if (contacts[m.user._id]) {
      //     m.user.name = contacts[m.user._id].name;
      //     m.user.image = contacts[m.user._id].image;
      //   }
      // });
      // setMessages([...messages]);
      // console.log(messages);
      // Izkomentarisano
      // JBG, Gifted chat nece da primeni izmene za user kada se resolvuju kontakti
      // Mozda nekad se baviti ovime ako bas bude pravilo problem
    }
  }, [contacts]);

  const setScreenTitle = (d) => {
    if (d.deleted) {
      navigation.navigate("Chats");
      return;
    }
    setGroupData(d);
    let names = [];
    let missing = [];
    let suggest = [{ id: "all", name: "All" }];
    d.participants.forEach((c_id) => {
      if (contacts[c_id] && c_id !== uid) {
        names.push(contacts[c_id].firstName);
        suggest.push({ id: c_id, name: contacts[c_id].firstName });
      } else missing.push(c_id);
    });
    // console.log("MISSING", missing);
    if (missing.length) checkMissing(missing);
    suggestions.current = suggest;

    navigation.setOptions({
      headerTitle: () => (
        <View style={{ alignItems: "center" }}>
          <Text style={{ color: "#FFF", fontFamily: "Lato_700Bold" }}>
            {d.name}
          </Text>
          <Text style={{ color: "#FFF", fontSize: 12 }}>
            {names.join(", ")}
          </Text>
        </View>
      ),
    });
  };

  const onUnmount = () => {
    // console.log("CALLING GROUP ONUNMOUNT");
    unsubscribe.current();
    unsubscribe2.current();
    keyboardHideListener.remove();
    keyboardShowListener.remove();
  };

  const loadAndListen = async () => {
    unsubscribe.current = firebase
      .firestore()
      .collection("group-chats")
      .doc(groupId)
      .collection("messages")
      .orderBy("createdAt", "asc")
      .limitToLast(30)
      .onSnapshot({ includeMetadataChanges: true }, (snap) => {
        let arr = [];
        snap.docs.forEach((m) => {
          const message = m.data();
          // console.log(m.metadata);
          arr.unshift({
            ...message,
            ...{
              _id: m.id,
              text: message.text,
              createdAt: message.createdAt
                ? message.createdAt.toDate()
                : new Date(),
              // uri: message.uri
              //   ? message.uri.startsWith("uploads")
              //     ? axios.defaults.baseURL +
              //       "../" +
              //       message.uri.split(".jpg")[0] +
              //       "_tn.jpg"
              //     : message.uri
              //   : "",
              sent: !m.metadata.hasPendingWrites,
              pending: m.metadata.hasPendingWrites,
              user: {
                _id: message.sender,
                name:
                  message.sender === uid
                    ? user.firstName + " " + user.lastName
                    : contacts[message.sender]?.name || "Unknown user",
                image:
                  message.sender === uid
                    ? user.image
                    : contacts[message.sender]?.image || "",
              },
            },
          });
        });
        setMessages(arr);
        firebase
          .firestore()
          .collection("users")
          .doc(uid)
          .collection("conversations")
          .doc(groupId)
          .set(
            {
              unread: 0,
              lastRead: firebase.firestore.FieldValue.serverTimestamp(),
            },
            { merge: true }
          );
      });
    unsubscribe2.current = firebase
      .firestore()
      .collection("group-chats")
      .doc(groupId)
      .onSnapshot((d) => {
        console.log("UNS2", d.data());
        setScreenTitle(d.data());
      });
  };

  const loadEarlier = () => {
    console.log("LOAD EARLIER");
    setLoadingEarlier(true);
    firebase
      .firestore()
      .collection("group-chats")
      .doc(groupId)
      .collection("messages")
      .orderBy("createdAt", "asc")
      .where("createdAt", "<", messages[messages.length - 1].createdAt)
      .limitToLast(10)
      .get()
      .then((snap) => {
        setLoadingEarlier(false);
        if (snap.size) {
          let newMessages = [];
          snap.docs.forEach((m) => {
            const message = m.data();
            newMessages.unshift({
              _id: m.id,
              text: message.text,
              createdAt: message.createdAt
                ? message.createdAt.toDate()
                : new Date(),
              // uri: message.uri
              //   ? message.uri.startsWith("uploads")
              //     ? axios.defaults.baseURL +
              //       "../" +
              //       message.uri.split(".jpg")[0] +
              //       "_tn.jpg"
              //     : message.uri
              //   : "",
              sent: !m.metadata.hasPendingWrites,
              pending: m.metadata.hasPendingWrites,
              user: {
                _id: message.sender,
                name:
                  message.sender === uid
                    ? user.firstName + " " + user.lastName
                    : contacts[message.sender]?.name || "Unknown user",
                image:
                  message.sender === uid
                    ? user.image
                    : contacts[message.sender]?.image || "",
              },
            });
          });
          setMessages([...messages, ...newMessages]);
        } else {
          setEndReached(true);
        }
      });
  };

  const sendText = () => {
    if (!messageText) return false;
    if (editMessage) {
      const message_id = editMessage._id;
      delete editMessage.pending;
      delete editMessage.sent;
      delete editMessage.user;
      delete editMessage.uri;
      delete editMessage._id;
      editMessage.text = messageText;
      editMessage.edited = true;
      console.log(editMessage);

      firebase
        .firestore()
        .collection("group-chats")
        .doc(groupId)
        .collection("messages")
        .doc(message_id)
        .set(editMessage);

      setMessageText("");
      setEditMessage(null);
    } else {
      const message = {};
      message.sender = uid;
      message.text = messageText;
      setMessageText("");
      message.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      executeSend(message, uuidv4());
    }
  };

  const executeSend = (message, message_id) => {
    console.log("EXECUTE SEND", groupData);
    let body = message.type ? message.type + " message" : message.text;
    if (replyTo) body = "Replied: " + body;

    axios.post("newGroupMessage", {
      participants: groupData.participants,
      body: replaceMentionValues(body, ({ name }) => `@${name}`),
      groupName: groupData.name,
      groupId,
    });

    if (replyTo) {
      message.replyTo = replyTo;
      delete message.replyTo.sent;
      delete message.replyTo.pending;
      delete message.replyTo.sent;
      delete message.replyTo.user;
      delete message.replyTo.edited;
      setReplyTo(null);
    }

    let batch = firebase.firestore().batch();

    let groupMessages = firebase
      .firestore()
      .collection("group-chats")
      .doc(groupId)
      .collection("messages")
      .doc(message_id);

    batch.set(groupMessages, message);

    let ownConversation = firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .collection("conversations")
      .doc(groupId);

    batch.set(
      ownConversation,
      {
        lastTimestamp: message.createdAt,
        lastMessage: message.type
          ? message.type + " message"
          : replaceMentionValues(message.text, ({ name }) => `@${name}`),
      },
      { merge: true }
    );

    groupData.participants.forEach((c) => {
      if (c !== uid) {
        batch.set(
          firebase
            .firestore()
            .collection("users")
            .doc(c)
            .collection("conversations")
            .doc(groupId),
          {
            lastTimestamp: message.createdAt,
            lastMessage: message.type
              ? message.type + " message"
              : replaceMentionValues(message.text, ({ name }) => `@${name}`),
            unread: firebase.firestore.FieldValue.increment(1),
          },
          { merge: true }
        );
      }
    });

    batch.commit();
  };

  const deleteMessage = (m) => {
    // console.log("DELETE", m);
    firebase
      .firestore()
      .collection("group-chats")
      .doc(groupId)
      .collection("messages")
      .doc(m._id)
      .delete();
  };
  const _onLongPress = (c, m) => {
    // console.log(c, m);
    console.log("LONG PRESS");

    let showSheet;
    if (c && c.actionSheet) {
      showSheet = c.actionSheet().showActionSheetWithOptions;
    } else showSheet = showActionSheetWithOptions;

    if (m.text && m.sender === uid) {
      const options = [
        "Reply",
        "Edit",
        "Copy Text",
        "Delete Message",
        "Cancel",
      ];

      const cancelButtonIndex = options.length - 1;
      showSheet(
        {
          options,
          cancelButtonIndex,
        },
        (buttonIndex) => {
          switch (buttonIndex) {
            case 0:
              //reply
              setReplyTo(m);
              break;
            case 1:
              //edit
              setEditMessage(m);
              setMessageText(m.text);
              break;
            case 2:
              Clipboard.setString(m.text);
              break;
            case 3:
              deleteMessage(m);
              break;
          }
        }
      );
    }

    if (m.text && m.sender !== uid) {
      const options = ["Reply", "Copy Text", "Cancel"];

      const cancelButtonIndex = options.length - 1;
      showSheet(
        {
          options,
          cancelButtonIndex,
        },
        (buttonIndex) => {
          switch (buttonIndex) {
            case 0:
              //reply
              setReplyTo(m);
              break;
            case 1:
              Clipboard.setString(m.text);
              break;
          }
        }
      );
    }
    if (m.type) {
      showSheet(
        {
          options: ["Reply", "Delete Message", "Cancel"],
          cancelButtonIndex: 2,
        },
        (buttonIndex) => {
          switch (buttonIndex) {
            case 0:
              //reply
              setReplyTo(m);
              break;
            case 1:
              deleteMessage(m);
              break;
          }
        }
      );
    }
  };
  const pickFromLibrary = async () => {
    ImagePicker.openPicker({
      multiple: true,
      compressImageMaxWidth: 1000,
      compressImageMaxHeight: 800,
    }).then((images) => {
      console.log(images);
      setPickedImages(images);
    });
  };

  const pickFile = async () => {
    const message_id = uuidv4();
    let result = await DocumentPicker.getDocumentAsync({
      copyToCacheDirectory: false,
    });
    console.log(result);
    if (!result.cancelled) {
      let type = "file";
      if (result.name.toLowerCase().endsWith(".jpg")) {
        type = "image";
        resizeAndUpload(result.uri, message_id, uploadDone);
      } else if (
        result.name.toLowerCase().endsWith(".mp4") ||
        result.name.toLowerCase().endsWith(".mov")
      ) {
        type = "video";
        upload(result.uri, message_id, uploadDone, type, result.name);
      } else {
        upload(result.uri, message_id, uploadDone, type, result.name);
      }

      firebase
        .firestore()
        .collection("group-chats")
        .doc(groupId)
        .collection("messages")
        .doc(message_id)
        .set({
          uploading: true,
          uri: result.uri,
          sender: uid,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          pending: true,
          type,
          filename: result.name,
        });
    }
  };

  const uploadDone = (message_id, url, type, filename, description) => {
    console.log("UPLOAD DONE CHAT DETAIL", message_id, url);
    const message = {
      uri: url,
      sender: uid,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      type,
      text: description || "",
      pending: true,
      filename: filename || "",
    };
    executeSend(message, message_id);
  };

  const sendGif = (url, width, height) => {
    console.log("SEND GIF", url);
    const message = {
      uri: url,
      sender: uid,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      type: "giphy",
      text: "",
      width,
      height,
      sent: true,
    };
    executeSend(message, uuidv4());
  };

  const renderReplyTo = () => {
    if (replyTo)
      return (
        <View
          style={{
            padding: 10,
            backgroundColor: colors.gray,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Ionicons name="ios-arrow-redo-sharp" size={22} color={colors.main} />
          {replyTo.type === "image" || replyTo.type === "giphy" ? (
            <Image
              source={{
                uri: fixUrl(replyTo.uri, true),
              }}
              style={{ width: 44, height: 44 }}
            />
          ) : null}
          <View style={{ flex: 1, marginHorizontal: 10 }}>
            <Text style={{ fontFamily: "Lato_700Bold" }}>
              {replyTo.sender === uid
                ? "You"
                : contacts[replyTo.sender]?.name || "Unknown user"}
              :
            </Text>
            {replyTo.text ? (
              <Text numberOfLines={1}>
                {replaceMentionValues(replyTo.text, ({ name }) => `@${name}`)}
              </Text>
            ) : null}
            {replyTo.type ? <Text>{replyTo.type} message</Text> : null}
          </View>
          <TouchableOpacity onPress={() => setReplyTo(null)}>
            <Ionicons name="close" size={22} />
          </TouchableOpacity>
        </View>
      );
    return null;
  };

  const openImage = (u) => {
    // console.log(u);
    let images = [];
    let key = -1;
    messages.forEach((m, k) => {
      if (m.type === "image" && m.uri)
        images.push({
          uri: fixUrl(m.uri),
        });
    });
    images.some((i, k) => {
      if (i.uri.indexOf(u) > -1) {
        key = k;
        return true;
      }
      return false;
    });
    // console.log(images);
    // console.log(key);
    setChatImages(images);
    setImageKey(key);
  };

  const openFile = (u) => {
    if (Platform.OS === "web") {
      window.open(fixUrl(u), "_blank").focus();
    } else {
      setShowFile(u);
    }
  };

  /*
const hashtagFormatter = (string) => {
	return string
		.split(/((?:^|\s)(?:#[a-z\d-]+))/gi)
		.filter(Boolean)
		.map((v, i) => {
			if (v.includes("#")) {
				return (
					<Text
						key={i}
						style={{ color: "#FC4954" }}
						onPress={() => DeviceEventEmitter.emit("hashtag-click", v)}
					>
						{v}
					</Text>
				);
			} else {
				return <Text key={i}>{v}</Text>;
			}
		});
};
*/

  const renderSystemMessage = (message) => {
    let text = message.text.replace(/\B\@([\w\-]+)/gim, (match, name) => {
      const u = match.substr(1);
      if (u === uid) return "You";
      if (contacts[u]) return `'${contacts[u].name}'`;
      return "Unknown user";
    });

    return (
      <View
        style={{
          width: "90%",
          maxWidth: 300,
          backgroundColor: colors.white,
          alignSelf: "center",
          marginBottom: 20,
          padding: 10,
          borderRadius: 10,
        }}
      >
        <Text style={{ textAlign: "center", opacity: 0.5 }}>
          [{dayjs(message.createdAt).format("HH:mm")}] {text}
        </Text>
      </View>
    );
  };

  const renderSuggestions =
    (suggestions) =>
    ({ keyword, onSuggestionPress }) => {
      // 4.2. Throttling keyword changes to fix flickering
      // https://github.com/dabakovich/react-native-controlled-mentions/issues/55
      const [calmKeyword, setCalmKeyword] = useState(keyword);
      useEffect(() => {
        const timeout = setTimeout(() => setCalmKeyword(keyword), 10);
        return () => clearTimeout(timeout);
      }, [keyword]);

      if (calmKeyword == null || keyword === undefined) {
        return null;
      }

      return (
        <View
          style={{
            position: "absolute",
            width: Dimensions.get("screen").width - 18,
            bottom: "100%",
            marginBottom: 15,
            backgroundColor: colors.white,
            borderRadius: 5,
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
            elevation: 5,
          }}
        >
          {suggestions
            .filter((one) =>
              one.name.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
            )
            .map((one) => (
              // 4.3. Wrap elements inside a `TouchableOpacity` from `react-native-gesture-handler` to fix tapping on Android items in absolute containers
              // https://github.com/facebook/react-native/issues/27232#issuecomment-819347427
              <TouchableOpacity
                key={one.id}
                onPress={() => onSuggestionPress(one)}
                style={{
                  padding: 12,
                  borderBottomColor: colors.bg,
                  borderBottomWidth: 1,
                }}
              >
                <Text
                  style={{ color: colors.main, fontFamily: "Lato_700Bold" }}
                >
                  @{one.name}
                </Text>
              </TouchableOpacity>
            ))}
        </View>
      );
    };

  const sendMultiImages = (descriptions) => {
    [...pickedImages].forEach((i, k) => {
      // console.log(i.path, i.width, i.height);
      // console.log(descriptions[k]);

      const message_id = uuidv4();

      // console.log(i);
      if (i.mime.startsWith("image")) {
        resizeAndUpload(i.path, message_id, uploadDone, descriptions[k]);
      } else {
        upload(
          i.path,
          message_id,
          uploadDone,
          "video",
          "video-file",
          descriptions[k]
        );
      }

      firebase
        .firestore()
        .collection("group-chats")
        .doc(groupId)
        .collection("messages")
        .doc(message_id)
        .set({
          uploading: true,
          uri: i.path,
          sender: uid,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          pending: true,
          type: "video",
          text: descriptions[k] || "",
        });
    });
    setPickedImages([]);
  };

  return (
    <>
      <GiftedChat
        messages={messages}
        placeholder="Enter message..."
        textInputProps={{
          autoCapitalize: "sentences",
        }}
        onLongPress={(c, m) => _onLongPress(c, m)}
        renderBubble={(props) => (
          <MyBubble
            {...props}
            reply={() => setReplyTo(props.currentMessage)}
            openImage={(u) => openImage(u)}
            openFile={(u) => openFile(u)}
            lastRead={lastRead}
          />
        )}
        renderSystemMessage={({ currentMessage }) =>
          renderSystemMessage(currentMessage)
        }
        user={{
          _id: uid,
          avatar: user?.image || "",
          name: user?.firstName + " " + user.lastName,
        }}
        renderUsernameOnMessage={true}
        scrollToBottom
        isTyping={false}
        renderChatFooter={renderReplyTo}
        renderInputToolbar={(props) => {
          return (
            <View>
              <View
                style={{
                  flexDirection: "row",
                  backgroundColor: colors.white,
                  borderTopColor: colors.gray,
                  borderTopWidth: 1,
                }}
              >
                {Platform.OS === "web" ? (
                  <>
                    <TouchableOpacity
                      style={{ padding: 8 }}
                      onPress={() => pickFile()}
                    >
                      <Ionicons name={"attach"} size={26} color={colors.main} />
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{ padding: 8 }}
                      onPress={() => setShowGiphy(true)}
                    >
                      <MaterialIcons
                        name={"gif"}
                        size={26}
                        color={colors.main}
                      />
                    </TouchableOpacity>
                    <Composer
                      {...props}
                      textInputStyle={{
                        fontFamily: "Lato_400Regular",
                        padding: 10,
                        borderRadius: 6,
                        color: colors.text,
                      }}
                      multiline
                      textInputProps={{
                        value: messageText,
                        onChangeText: (e) => setMessageText(e),
                        autoCapitalize: "sentences",
                      }}
                    />
                  </>
                ) : (
                  <MentionInput
                    inputRef={props.textInputProps?.ref}
                    accessible
                    accessibilityLabel={props.placeholder}
                    placeholder={props.placeholder}
                    // placeholderTextColor={props.placeholderTextColor}
                    // editable={!props.disableComposer}
                    // onLayout={(e) => {
                    //   if (props.onInputSizeChanged) {
                    //     props.onInputSizeChanged({
                    //       width: e.nativeEvent.layout.width,
                    //       height: e.nativeEvent.layout.height + 17,
                    //     });
                    //   }
                    // }}
                    onChange={(e) => {
                      setMessageText(e);
                      props.onTextChanged(e);
                    }}
                    containerStyle={{
                      height: props.composerHeight,
                      ...styles.textInputContainer,
                    }}
                    style={{
                      fontFamily: "Lato_400Regular",
                      padding: 10,
                      borderRadius: 6,
                      color: colors.text,
                      fontSize: 16,
                    }}
                    // autoFocus={props.textInputAutoFocus}
                    value={messageText}
                    // onChangeText={(e) => setMessageText(e)}
                    enablesReturnKeyAutomatically
                    underlineColorAndroid={"transparent"}
                    // keyboardAppearance={props.keyboardAppearance}
                    // keyboardType="email-address"
                    partTypes={[
                      {
                        trigger: "@",
                        renderSuggestions: (p) =>
                          renderSuggestions(suggestions.current)(p),
                        textStyle: {
                          fontFamily: "Lato_700Bold",
                          color: colors.main,
                        },
                        isInsertSpaceAfterMention: true,
                      },
                    ]}
                  />
                )}

                <TouchableOpacity onPress={() => sendText()}>
                  <Ionicons
                    name={"send"}
                    color={colors.main}
                    style={{ padding: 10 }}
                    size={24}
                  />
                </TouchableOpacity>
              </View>
              <View
                style={{
                  height: 44,
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: colors.white,
                  paddingLeft: 10,
                }}
              >
                <TouchableOpacity
                  style={{ padding: 8 }}
                  onPress={() => pickFromLibrary()}
                >
                  <Ionicons name={"images"} size={26} color={colors.main} />
                </TouchableOpacity>
                {/* <TouchableOpacity
                    style={{ padding: 8 }}
                    onPress={() => pickVideo("")}
                  >
                    <Ionicons name={"videocam"} size={26} color={colors.main} />
                  </TouchableOpacity> */}
                <TouchableOpacity
                  style={{ padding: 8 }}
                  onPress={() => setShowGiphy(true)}
                >
                  <MaterialIcons name={"gif"} size={26} color={colors.main} />
                </TouchableOpacity>
                <TouchableOpacity
                  style={{ padding: 8 }}
                  onPress={() => pickFile()}
                >
                  <Ionicons name={"attach"} size={26} color={colors.main} />
                </TouchableOpacity>
              </View>
            </View>
          );
        }}
        alwaysShowSend
        textInputStyle={{
          backgroundColor: colors.input,
          borderRadius: 20,
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 15,
          color: colors.inputText,
        }}
        infiniteScroll
        renderAvatar={(props) =>
          renderAvatar(navigation, props.currentMessage.user)
        }
        onLoadEarlier={() => loadEarlier()}
        loadEarlier
        bottomOffset={Platform.OS === "android" ? -paddingBottom : 34}
        renderLoadEarlier={(props) => {
          if (endReached || messages.length < 30) return null;
          else {
            if (loadingEarlier)
              return (
                <View style={{ margin: 10 }}>
                  <ActivityIndicator color="#333" />
                </View>
              );
            return <LoadEarlier {...props} />;
          }
        }}
      />
      <GiphyPicker
        show={showGiphy}
        close={() => setShowGiphy(false)}
        pick={sendGif}
      />
      <ImagesPreSend
        images={pickedImages}
        close={() => setPickedImages([])}
        send={(descriptions) => sendMultiImages(descriptions)}
      />

      {imageKey !== -1 ? (
        <ImageView
          images={chatImages}
          imageIndex={imageKey}
          visible={imageKey !== -1}
          onRequestClose={() => setImageKey(-1)}
        />
      ) : null}
      <Modal visible={showFile ? true : false} animationType="slide">
        <SafeAreaView
          style={{
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <View style={{ flex: 1 }} />
          <TouchableOpacity
            onPress={() => {
              setShowFile(null);
            }}
            style={{ flex: 1, alignItems: "flex-end", paddingHorizontal: 20 }}
          >
            <Text
              style={{
                fontSize: 16,
                color: colors.main,
              }}
            >
              Done
            </Text>
          </TouchableOpacity>
        </SafeAreaView>

        {showFile ? (
          <WebView
            source={{
              uri: fixUrl(showFile),
            }}
            style={{ marginTop: 20 }}
          />
        ) : null}
      </Modal>
    </>
  );
}

const styles = StyleSheet.create({
  // 1.2. Copying text input container style
  // https://github.com/FaridSafi/react-native-gifted-chat/blob/master/src/Composer.tsx#L9
  textInputContainer: {
    flex: 1,
    marginLeft: 10,
    ...Platform.select({
      web: {
        paddingTop: 6,
        paddingLeft: 4,
      },
    }),
    marginTop: Platform.select({
      ios: 6,
      android: 0,
      web: 6,
    }),
    marginBottom: Platform.select({
      ios: 5,
      android: 3,
      web: 4,
    }),
  },
});
