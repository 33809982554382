import swal from "sweetalert2";

let answerCall;
let endCall;
export default class RNCallKeep {
  static setup() {
    return new Promise((resolve, reject) => {
      resolve();
    });
  }
  static addEventListener(e, callback) {
    console.log("ADD EVENT LISTENER", e);
    if (e === "answerCall") answerCall = callback;
    if (e === "endCall") endCall = callback;
  }
  static startCall() {
    console.log("START CALL SHIM");
  }
  static endCall() {
    console.log("END CALL SHIM");
  }
  static rejectCall() {
    console.log("REJECT CALL SHIM");
  }
  static displayIncomingCall(recipient, number, name, type, videoCall) {
    console.log("DISPLAY CALL SHIM");
    swal
      .fire({
        title: "Incoming Call",
        text: `From ${name}, ${recipient}, ${number} - ${
          videoCall ? "Video" : "Audio"
        }`,
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Answer",
        cancelButtonText: "Decline",
        reverseButtons: true,
      })
      .then((v) => {
        if (v.value) {
          console.log("ANSWER");
          answerCall();
        } else {
          endCall();
        }
      });
  }
}
