import React from "react";
import { TextInput, TextPropTypes } from "react-native";
import PropTypes from "prop-types";
import { useColors } from "../store";

export default function T(props) {
  const { colors } = useColors();
  return (
    <TextInput
      autoCompleteType={"off"}
      autoCorrect={false}
      {...props}
      style={[
        {
          color: colors.text,
          padding: 10,
          borderColor: colors.gray,
          borderWidth: 1,
          borderRadius: 5,
          backgroundColor: colors.input,
          fontFamily: "Lato_400Regular",
        },
        { ...props.style },
      ]}
    >
      {props.children}
    </TextInput>
  );
}

T.propTypes = { ...TextPropTypes };
