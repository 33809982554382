import React, { useEffect, useRef, useState } from "react";
import { useNavigation, useRoute } from "@react-navigation/native";
import {
  View,
  Pressable,
  DeviceEventEmitter,
  Platform,
  Modal,
  ActivityIndicator,
  Image,
  ScrollView,
} from "react-native";
import Text from "../components/Text";
import TextInput from "../components/TextInput";
import { useColors, useContacts, useUploads, useUser } from "../store";
import Avatar from "../components/Avatar";
import firebase from "firebase/app";
import "firebase/firestore";
import Ionicons from "@expo/vector-icons/Ionicons";
import Entypo from "@expo/vector-icons/Entypo";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import axios from "axios";
import * as ImagePicker from "expo-image-picker";
import * as ImageManipulator from "expo-image-manipulator";
import { useActionSheet } from "@expo/react-native-action-sheet";
import ContactList from "./ContactList";
import { Confirm } from "../util/Alert";
import { v4 as uuidv4 } from "uuid";
import Clipboard from "@react-native-community/clipboard";

function DataURIToBlob(dataURI) {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
}

export default function Group() {
  const route = useRoute();
  const navigation = useNavigation();
  const unsubscribe = useRef();
  const unsubscribe2 = useRef();
  const groupId = route.params.id;
  const [groupData, setGroupData] = useState({});
  const { contacts, checkMissing } = useContacts();
  const [image, setImage] = useState(groupData.image || "");
  const [uploading, setUploading] = useState(false);
  const { showActionSheetWithOptions } = useActionSheet();
  const { uid, user } = useUser();
  const { colors } = useColors();
  const [editName, setEditName] = useState(false);
  const [groupSelected, setGroupSelected] = useState({});
  const [contactsModal, setContactsModal] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (!unsubscribe.current) {
      loadAndListen();
    }
    return onUnmount;
  }, []);

  const onUnmount = () => {
    unsubscribe.current();
  };

  const setScreenTitle = (d) => {
    if (d.deleted) {
      navigation.navigate("Chats");
      return;
    }
    setGroupData(d);
    let names = [];
    let missing = [];
    d.participants.forEach((c_id) => {
      if (contacts[c_id]) names.push(contacts[c_id].firstName);
      else missing.push(c_id);
    });
    if (missing.length) checkMissing(missing);
    navigation.setOptions({
      title: d.name,
    });
    setIsAdmin(d.owner === uid || (d.admins && d.admins.includes(uid)));
  };

  const loadAndListen = async () => {
    unsubscribe.current = firebase
      .firestore()
      .collection("group-chats")
      .doc(groupId)
      .onSnapshot((d) => {
        console.log("GROUP", d.data());
        setScreenTitle(d.data());
      });
  };

  const pickImage = async () => {
    if (isAdmin) {
      if (Platform.OS !== "web") {
        showActionSheetWithOptions(
          {
            options: ["Camera", "Library", "Cancel"],
            cancelButtonIndex: 2,
            title: "Select image from:",
          },
          async (k) => {
            if (k === 0) {
              const { status } =
                await ImagePicker.requestCameraPermissionsAsync();
              if (status !== "granted") {
                alert(
                  "Sorry, we need camera roll permissions to make this work!"
                );
              }
              let result = await ImagePicker.launchCameraAsync({
                allowsEditing: true,
                aspect: [4, 4],
                quality: 1,
              });
              // console.log(result);

              if (!result.cancelled) {
                upload(result.uri);
              }
            }
            if (k === 1) {
              const { status } =
                await ImagePicker.requestMediaLibraryPermissionsAsync();
              if (status !== "granted") {
                alert(
                  "Sorry, we need camera roll permissions to make this work!"
                );
              }

              let result = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsEditing: true,
                aspect: [4, 4],
                quality: 1,
              });
              // console.log(result);

              if (!result.cancelled) {
                upload(result.uri);
              }
            }
          }
        );
      } else {
        let result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: true,
          aspect: [4, 3],
          quality: 1,
        });
        // console.log(result);

        if (!result.cancelled) {
          upload(result.uri);
        }
      }
    }
  };

  const upload = async (input) => {
    setUploading(true);
    const result = await ImageManipulator.manipulateAsync(
      input,
      [{ resize: { width: 800 } }],
      { compress: 0.6, format: ImageManipulator.SaveFormat.JPEG }
    );
    const url = result.uri;

    setImage(url);

    const formData = new FormData();
    if (Platform.OS === "web") {
      formData.append("file", DataURIToBlob(url), "group-photo.jpg");
    } else {
      formData.append("file", {
        type: "image/jpeg",
        name: "group-photo.jpg",
        uri: Platform.OS === "android" ? url : url.replace("file://", ""),
      });
    }
    axios
      .post("upload", formData)
      .then((d) => {
        setUploading(false);
        if (!d.data.url) {
          alert("Error uploading picture.");
          setImage("");
        } else {
          saveGroupData({ ...groupData, ...{ image: d.data.url } });
          const text = `@${uid} changed the group photo`;
          firebase
            .firestore()
            .collection("group-chats")
            .doc(groupId)
            .collection("messages")
            .add({
              sender: uid,
              text,
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
              system: true,
            });
        }
      })
      .catch(() => {
        alert("Error uploading picture.");
        setUploading(false);
      });
  };

  const saveGroupData = (d, addLastTimestamp) => {
    return new Promise((resolve) => {
      setScreenTitle(d);
      const batch = firebase.firestore().batch();
      delete d.lastTimestamp;
      delete d.lastMessage;
      delete d.lastRead;
      delete d.unread;
      if (addLastTimestamp)
        d.lastTimestamp = firebase.firestore.FieldValue.serverTimestamp();

      batch.set(
        firebase.firestore().collection("group-chats").doc(groupId),
        d,
        {
          merge: true,
        }
      );

      batch.set(
        firebase
          .firestore()
          .collection("users")
          .doc(groupData.owner)
          .collection("conversations")
          .doc(groupId),
        d,
        { merge: true }
      );

      groupData.participants.forEach((c) => {
        batch.set(
          firebase
            .firestore()
            .collection("users")
            .doc(c)
            .collection("conversations")
            .doc(groupId),
          d,
          { merge: true }
        );
      });

      batch.commit().then(() => resolve());
    });
  };

  const avatarUrl = (_uid) => {
    if (_uid === uid) return user.image;
    if (contacts[_uid]?.image) return contacts[_uid].image;
    return null;
  };

  const avatarName = (_uid) => {
    if (_uid === uid) return "Me";
    if (contacts[_uid]?.name) return contacts[_uid].name;
    return "Unknown user";
  };

  const openUserMenu = (c) => {
    if (c === groupData.owner || !isAdmin) {
      const options = ["View profile", "Cancel"];
      showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex: 1,
        },
        (k) => {
          if (k === 0) viewProfile(c);
        }
      );
      return false;
    }

    if (isAdmin) {
      const options = [
        "View profile",
        groupData.admins && groupData.admins.includes(c)
          ? "Remove admin"
          : "Make admin",
        "Remove from group",
        "Cancel",
      ];
      showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex: 3,
        },
        (k) => {
          if (k === 0) viewProfile(c);
          if (k === 1) toggleAdmin(c);
          if (k === 2) removeFromGroup(c);
        }
      );
    }
  };

  const viewProfile = (id) => {
    navigation.navigate("ProfileView", { id });
  };

  const toggleAdmin = (id) => {
    if (groupData.admins && groupData.admins.includes(id)) {
      groupData.admins = groupData.admins.filter((v) => v !== id);
    } else {
      if (!groupData.admins) groupData.admins = [];
      groupData.admins.push(id);
    }
    saveGroupData({ ...groupData });
  };

  const removeFromGroup = async (id) => {
    Confirm("Are you sure you want to remove user from group?").then(
      async () => {
        if (groupData.admins && groupData.admins.includes(id)) {
          groupData.admins = groupData.admins.filter((v) => v !== id);
        }
        groupData.participants = groupData.participants.filter((v) => v !== id);
        await saveGroupData({ ...groupData });
        firebase
          .firestore()
          .collection("users")
          .doc(id)
          .collection("conversations")
          .doc(groupId)
          .delete();

        const text = `@${uid} removed @${id} from group`;

        firebase
          .firestore()
          .collection("group-chats")
          .doc(groupId)
          .collection("messages")
          .add({
            sender: uid,
            text,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            system: true,
          });
      }
    );
  };

  const selectContact = (c) => {
    if (groupSelected[c._id]) delete groupSelected[c._id];
    else groupSelected[c._id] = c;
    setGroupSelected({ ...groupSelected });
  };

  const addToGroup = () => {
    setContactsModal(false);
    setGroupSelected({});
    // console.log(Object.keys(groupSelected));
    Object.keys(groupSelected).forEach((c) => {
      groupData.participants.push(c);
    });
    saveGroupData(groupData, true);

    const text = `@${uid} added ${Object.keys(groupSelected)
      .map((c) => "@" + c)
      .join(", ")} to group`;

    firebase
      .firestore()
      .collection("group-chats")
      .doc(groupId)
      .collection("messages")
      .add({
        sender: uid,
        text,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        system: true,
      });
  };

  const leaveGroup = () => {
    Confirm("Are you sure you want to leave this group?").then(() => {
      firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .collection("conversations")
        .doc(groupId)
        .delete();

      const text = `@${uid} left group`;
      firebase
        .firestore()
        .collection("group-chats")
        .doc(groupId)
        .collection("messages")
        .add({
          sender: uid,
          text,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          system: true,
        });

      groupData.participants = groupData.participants.filter((v) => v !== uid);
      if (groupData.admins)
        groupData.admins = groupData.admins.filter((v) => v !== uid);
      saveGroupData(groupData);
      navigation.navigate("Chats");
    });
  };

  const deleteGroup = () => {
    Confirm(
      "Are you sure you want to delete this group?",
      "All messages will be lost."
    ).then(() => {
      const batch = firebase.firestore().batch();

      groupData.participants.forEach((c) => {
        batch.delete(
          firebase
            .firestore()
            .collection("users")
            .doc(c)
            .collection("conversations")
            .doc(groupId)
        );
      });

      batch.commit().then(() => {
        firebase
          .firestore()
          .collection("group-chats")
          .doc(groupId)
          .set({ deleted: true }, { merge: true });
      });
    });
  };

  return (
    <>
      <ScrollView>
        <View style={{ margin: 20, alignItems: "center" }}>
          <Pressable
            onPress={pickImage}
            style={{ alignItems: "center" }}
            disabled={uploading}
          >
            <Avatar
              url={image || groupData.image}
              size={100}
              uploading={uploading}
            />
            {isAdmin ? (
              <Text style={{ opacity: 0.5, fontSize: 13, marginTop: 5 }}>
                Click to change group photo
              </Text>
            ) : null}
          </Pressable>
          {editName ? (
            <View style={{ flexDirection: "row", marginTop: 20 }}>
              <TextInput
                style={{ width: "90%" }}
                value={groupData.name}
                onChangeText={(e) =>
                  setGroupData({ ...groupData, ...{ name: e } })
                }
                onSubmitEditing={() => {
                  saveGroupData(groupData);
                  setEditName(false);
                  const text = `@${uid} renamed group to '${groupData.name}'`;
                  firebase
                    .firestore()
                    .collection("group-chats")
                    .doc(groupId)
                    .collection("messages")
                    .add({
                      sender: uid,
                      text,
                      createdAt:
                        firebase.firestore.FieldValue.serverTimestamp(),
                      system: true,
                    });
                }}
                returnKeyType={"done"}
                autoFocus
              />
              <Pressable
                style={{
                  backgroundColor: colors.main,
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 10,
                  borderRadius: 4,
                  paddingHorizontal: 10,
                  marginVertical: 1,
                }}
                onPress={() => {
                  saveGroupData(groupData);
                  setEditName(false);
                  const text = `@${uid} renamed group to '${groupData.name}'`;
                  firebase
                    .firestore()
                    .collection("group-chats")
                    .doc(groupId)
                    .collection("messages")
                    .add({
                      sender: uid,
                      text,
                      createdAt:
                        firebase.firestore.FieldValue.serverTimestamp(),
                      system: true,
                    });
                }}
              >
                <Text style={{ color: "#FFF", fontFamily: "Lato_700Bold" }}>
                  Save
                </Text>
              </Pressable>
            </View>
          ) : (
            <Text
              style={{
                fontSize: 22,
                fontFamily: "Lato_700Bold",
                marginTop: 20,
              }}
            >
              {groupData.name}{" "}
              {isAdmin ? (
                <Pressable onPress={() => setEditName(true)}>
                  <Ionicons name="pencil" size={20} color={colors.main} />
                </Pressable>
              ) : null}
            </Text>
          )}
          <View style={{ marginTop: 30, width: "100%" }}>
            <Text
              style={{
                color: colors.text,
                fontSize: 18,
                textAlign: "center",
              }}
            >
              Group members
            </Text>
            <View
              style={{
                borderBottomColor: colors.gray,
                borderBottomWidth: 1,
                marginBottom: 20,
                paddingBottom: 5,
              }}
            />
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Avatar url={avatarUrl(groupData.owner)} size={50} />
              <View style={{ marginLeft: 10, flex: 1 }}>
                <Text style={{ fontSize: 16, fontFamily: "Lato_700Bold" }}>
                  {avatarName(groupData.owner)}
                </Text>
                <Text style={{ opacity: 0.6, fontSize: 14 }}>Group owner</Text>
              </View>
              {groupData.owner !== uid ? (
                <Pressable onPress={() => openUserMenu(groupData.owner)}>
                  <Entypo
                    name="dots-three-horizontal"
                    color={colors.main}
                    size={20}
                  />
                </Pressable>
              ) : null}
            </View>
            {groupData.participants &&
              groupData.participants.map((c) => (
                <React.Fragment key={c}>
                  {c !== groupData.owner ? (
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        borderTopColor: colors.gray,
                        borderTopWidth: 1,
                        marginTop: 7,
                        paddingTop: 7,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          flex: 1,
                          alignItems: "center",
                        }}
                      >
                        <Avatar url={avatarUrl(c)} size={50} />
                        <View style={{ marginLeft: 10 }}>
                          <Text
                            style={{ fontSize: 16, fontFamily: "Lato_700Bold" }}
                          >
                            {avatarName(c)}
                          </Text>
                          <Text style={{ opacity: 0.6, fontSize: 14 }}>
                            {groupData.admins && groupData.admins.includes(c)
                              ? "Admin"
                              : "User"}
                          </Text>
                        </View>
                      </View>
                      {uid !== c ? (
                        <Pressable onPress={() => openUserMenu(c)}>
                          <Entypo
                            name="dots-three-horizontal"
                            color={colors.main}
                            size={20}
                          />
                        </Pressable>
                      ) : null}
                    </View>
                  ) : null}
                </React.Fragment>
              ))}
            {isAdmin ? (
              <Pressable
                style={{
                  backgroundColor: colors.main,
                  padding: 10,
                  marginTop: 20,
                  borderRadius: 4,
                }}
                onPress={() => {
                  setContactsModal(true);
                }}
              >
                <Text
                  style={{
                    color: "#FFF",
                    textAlign: "center",
                    fontFamily: "Lato_700Bold",
                    fontSize: 16,
                  }}
                >
                  Add contacts to group
                </Text>
              </Pressable>
            ) : null}
            {groupData.owner === uid ? (
              <Pressable
                style={{
                  backgroundColor: colors.red,
                  padding: 10,
                  marginTop: 20,
                  borderRadius: 4,
                }}
                onPress={() => {
                  deleteGroup();
                }}
              >
                <Text
                  style={{
                    color: "#FFF",
                    textAlign: "center",
                    fontFamily: "Lato_700Bold",
                    fontSize: 16,
                  }}
                >
                  Delete group
                </Text>
              </Pressable>
            ) : (
              <Pressable
                style={{
                  backgroundColor: colors.red,
                  padding: 10,
                  marginTop: 20,
                  borderRadius: 4,
                }}
                onPress={() => {
                  leaveGroup();
                }}
              >
                <Text
                  style={{
                    color: "#FFF",
                    textAlign: "center",
                    fontFamily: "Lato_700Bold",
                    fontSize: 16,
                  }}
                >
                  Leave group
                </Text>
              </Pressable>
            )}
          </View>
        </View>
      </ScrollView>
      <Modal
        visible={contactsModal}
        presentationStyle={"pageSheet"}
        animationType={"slide"}
      >
        <View style={{ backgroundColor: colors.white, flex: 1 }}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
              padding: 5,
              marginBottom: 10,
              backgroundColor: colors.main,
            }}
          >
            <View style={{ flex: 1 }}>
              <Pressable
                onPress={() => {
                  setContactsModal(false);
                  setGroupSelected({});
                }}
                style={{ padding: 10 }}
              >
                <Text style={{ color: "#FFF" }}>Cancel</Text>
              </Pressable>
            </View>
            <Text
              style={{
                color: "#FFF",
                flex: 1,
                textAlign: "center",
                fontSize: 16,
                fontFamily: "Lato_700Bold",
              }}
            >
              Start Chat
            </Text>
            <View style={{ flex: 1, alignItems: "flex-end" }}>
              {Object.keys(groupSelected).length ? (
                <Pressable onPress={() => addToGroup()} style={{ padding: 10 }}>
                  <Text style={{ color: "#FFF" }}>Next</Text>
                </Pressable>
              ) : null}
            </View>
          </View>
          <ContactList
            onSelect={(c) => selectContact(c)}
            groupChat={true}
            startGroupChat={() => {}}
            groupSelected={groupSelected}
            hide={groupData.participants}
          />
        </View>
      </Modal>
    </>
  );
}
