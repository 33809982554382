import create from "zustand";
import { io } from "socket.io-client";
import { throttle } from "lodash";
import {
  RTCPeerConnection,
  RTCIceCandidate,
  RTCSessionDescription,
  RTCView,
  MediaStream,
  MediaStreamTrack,
  mediaDevices,
} from "react-native-webrtc-web-shim";
import InCallManager from "../shims/InCallManager";
import RNCallKeep from "../shims/RNCallKeepShim";
import axios from "axios";
import { Info } from "../util/Alert";
// import Peer from "simple-peer";
import { useContacts, useUser, useCallHistory } from ".";
import { DeviceEventEmitter, Platform } from "react-native";

const options = {
  ios: {
    appName: "Next.chat",
    includesCallsInRecents: true,
  },
  android: {
    alertTitle: "Permissions required",
    alertDescription: "This application needs to access your phone accounts",
    cancelButton: "Cancel",
    okButton: "ok",
    imageName: "phone_account_icon",
    // additionalPermissions: [PermissionsAndroid.PERMISSIONS.example],
    // Required to get audio in background when using Android 11
    foregroundService: {
      channelId: "chat.next.app",
      channelName: "Foreground service for my app",
      notificationTitle: "My app is running on background",
      notificationIcon: "Path to the resource icon of the notification",
    },
  },
};

const servers = {
  iceServers: [
    { url: "stun:stun01.sipphone.com" },
    { url: "stun:stun.ekiga.net" },
    { url: "stun:stun.fwdnet.net" },
    { url: "stun:stun.ideasip.com" },
    { url: "stun:stun.iptel.org" },
    { url: "stun:stun.rixtelecom.se" },
    { url: "stun:stun.schlund.de" },
    { url: "stun:stun.l.google.com:19302" },
    { url: "stun:stun1.l.google.com:19302" },
    { url: "stun:stun2.l.google.com:19302" },
    { url: "stun:stun3.l.google.com:19302" },
    { url: "stun:stun4.l.google.com:19302" },
    { url: "stun:stunserver.org" },
    { url: "stun:stun.softjoys.com" },
    { url: "stun:stun.voiparound.com" },
    { url: "stun:stun.voipbuster.com" },
    { url: "stun:stun.voipstunt.com" },
    { url: "stun:stun.voxgratia.org" },
    { url: "stun:stun.xten.com" },
    {
      url: "turn:numb.viagenie.ca",
      credential: "muazkh",
      username: "webrtc@live.com",
    },
    {
      url: "turn:192.158.29.39:3478?transport=udp",
      credential: "JZEOEt2V3Qb0y27GRntt2u2PAYA=",
      username: "28224511:1379330808",
    },
    {
      url: "turn:192.158.29.39:3478?transport=tcp",
      credential: "JZEOEt2V3Qb0y27GRntt2u2PAYA=",
      username: "28224511:1379330808",
    },
  ],
};

// Global State
let pc;
let socket;
let cachedOffer = [];
let callInterval;
let connectingSeconds = 0;
let connectingInterval;
let durationInterval;
let _duration = 0;
let myUid;
// let peer;

let typingDebounced = throttle((uid) => {
  // console.log(uid);
  socket.emit("typing", uid);
}, 3000);

const getLocalStream = (withVideo) => {
  return new Promise(async (resolve, reject) => {
    try {
      const sourceInfos = await mediaDevices.enumerateDevices();
      // console.log(sourceInfos);
      let videoSourceId;
      let isFront = true;
      sourceInfos.some((sourceInfo) => {
        if (
          sourceInfo.kind == "videoinput" &&
          sourceInfo.facing == (isFront ? "front" : "environment")
        ) {
          videoSourceId = sourceInfo.deviceId;
          return true;
        }
        return false;
      });

      let localStream = await mediaDevices.getUserMedia({
        audio: true,
        video: withVideo
          ? {
              width: 640,
              height: 480,
              frameRate: 30,
              facingMode: isFront ? "user" : "environment",
              deviceId: videoSourceId,
            }
          : false,
      });
      resolve(localStream);
    } catch (e) {
      console.log("Couldn't get local stream", e);
      Info(
        "Error",
        "Couldn't get your local video. Have you allowed Next.chat to use your camera and microphone?"
      );
      reject(e);
    }
  });
};

const useSocket = create((set, get) => ({
  typingData: {},
  localStream: undefined,
  remoteStream: undefined,
  state: undefined,
  offer: undefined,
  recipient: undefined,
  videoCall: false,
  muted: false,
  duration: 0,

  setOffer: (offer) => set({ offer }),
  setMessages: (messages) => set({ messages }),
  toggleMute: () => {
    get()
      .localStream.getAudioTracks()
      .forEach((track) => {
        track.enabled = !track.enabled;
        set({ muted: !track.enabled });
      });
  },
  connect: async (uid) => {
    myUid = uid;
    RNCallKeep.setup(options);
    //   .then(() => {
    //   console.log("RNCALL KEEP is setuped");
    // });

    pc = new RTCPeerConnection(servers);
    // console.log(
    //   "connect web socket",
    //   axios.defaults.baseURL.replace("api/", "")
    // );

    socket = io(axios.defaults.baseURL.replace("api/", ""));
    socket.on("connect", () => {
      console.log(
        "Connected to web socket",
        axios.defaults.baseURL.replace("api/", "")
      );

      socket.emit("auth", uid);
    });
    socket.on("typing", (uid) => {
      // console.log("received typing from", uid);
      let t = get().typingData;
      t[uid] = true;
      set({ typingData: t });
      setTimeout(() => {
        t[uid] = false;
        set({ typingData: t });
      }, 3000);
    });
    socket.on("direct", async (recipient, data) => {
      // console.log("received direct", recipient, data);

      if (!cachedOffer.length && data.cachedOffer && data.cachedOffer.length) {
        // console.log(
        //   "INCOMING CALL",
        //   data.phone,
        //   data.name,
        //   recipient,
        //   data.hasVideo
        // );
        set({
          state: "incoming",
          recipient,
          offer: data.offer,
          videoCall: data.hasVideo,
          muted: false,
        });
        RNCallKeep.displayIncomingCall(
          recipient,
          useContacts.getState().contacts[recipient]?.phone || data.phone,
          useContacts.getState().contacts[recipient]?.name || data.name,
          "number",
          data.hasVideo
        );

        socket.emit("direct", recipient, {
          ringing: true,
        });
        cachedOffer = data.cachedOffer;
      }

      // console.log(pc);
      // if (data.canates && pc.currentLocalDescription) {
      //   console.log("GOT OFFER CANDIDATES");
      //   data.candidates.forEach((c) => {
      //     pc.addIceCandidate(new RTCIceCandidate(c));
      //   });
      // }

      if (data.answerCandidate) {
        console.log("GOT ANSWER CANDIDATE", data.answerCandidate);
        pc.addIceCandidate(new RTCIceCandidate(data.answerCandidate));
        InCallManager.stopRingback();
      }

      if (data.answer) {
        console.log("SETTING ANSWER");
        set({
          state: "active",
          recipient,
        });
        clearInterval(connectingInterval);
        get().callStarted();
        pc.setRemoteDescription(new RTCSessionDescription(data.answer));
      }

      if (data.end_call) {
        console.log("RECEIVED END CALL");
        get().stopStreamsClearCall(recipient);
      }

      if (data.decline_call) {
        console.log("RECEIVED DECLINE CALL");
        get().stopStreamsClearCall(recipient);
        RNCallKeep.rejectCall(recipient);
      }
      if (data.ringing) {
        console.log("RECEIVED Ringing", get().videoCall);
        set({ state: "ringing" });
        InCallManager.start({
          media: get().videoCall ? "video" : "audio",
          ringback: "_BUNDLE_",
        });
        if (callInterval) clearInterval(callInterval);
      }
    });

    DeviceEventEmitter.addListener("startCallByPhone", (data) => {
      // console.log("RECEIVED EVENT", data);
      get().startCallByPhone(data);
    });

    RNCallKeep.addEventListener("answerCall", () => {
      // console.log("answerCall", data);
      get().answerCall();
    });

    RNCallKeep.addEventListener(
      "didReceiveStartCallAction",
      ({ handle, callUUID, name }) => {
        console.log("didReceiveStartCallAction", handle, callUUID, name);
        if (!get().recipient) {
          get().startCallByPhone(handle, false);
        }
      }
    );

    RNCallKeep.addEventListener("didLoadWithEvents", (e) => {
      console.log("didLoadWithEvents", e);
      if (e && e.length === 1) {
        if (e[0].name === "RNCallKeepDidReceiveStartCallAction") {
          if (!get().recipient) {
            get().startCallByPhone(e[0].data.handle, e[0].data.video);
          }
        }
      }
    });

    // RNCallKeep.addEventListener("didDisplayIncomingCall", () => {
    //   console.log("didDisplayIncomingCall");
    //   // socket.emit("direct", get().recipient, {
    //   //   ringing: true,
    //   // });
    // });

    RNCallKeep.addEventListener("endCall", () => {
      console.log("endCall", get().state);
      if (get().state === "incoming") get().declineCall(get().recipient);
      if (get().state === "active") get().endCall(get().recipient);
    });
  },
  meTyping: (uid) => {
    typingDebounced(uid);
  },
  startCallByPhone: (p, withVideo) => {
    const result = useContacts.getState().findByPhone(p);
    if (!result || !result.hasNext) {
      Info("User not found.", "User might not have next app installed.");
      return false;
    }
    get().call(result._id, withVideo);
  },
  call: async (recipient, hasVideo) => {
    const ls = await getLocalStream(hasVideo);
    cachedOffer = [];

    pc.addStream(ls);

    pc.onaddstream = (event) => {
      set({ remoteStream: event.stream });
    };

    // Create offer
    const offer = await pc.createOffer({
      offerToReceiveAudio: true,
      offerToReceiveVideo: hasVideo,
    });
    await pc.setLocalDescription(offer);

    cachedOffer.push(offer);

    pc.onicecandidate = (event) => {
      if (event.candidate) {
        console.log("Get candidates for CALLER");
        cachedOffer.push(event.candidate.toJSON());
        // socket.emit("direct", recipient, {
        //   offerCandidate: event.candidate.toJSON(),
        // });
      }
    };

    // console.log("CALL", localStream);

    set({
      state: "calling",
      recipient,
      localStream: ls,
      videoCall: hasVideo,
      muted: false,
    });

    if (Platform.OS === "ios")
      RNCallKeep.startCall(
        recipient,
        useContacts.getState().contacts[recipient]?.phone || "Unknown",
        useContacts.getState().contacts[recipient]?.name || "Unknown",
        "number",
        hasVideo
      );

    connectingInterval = setInterval(() => {
      connectingSeconds++;
      // console.log("connectingSeconds", connectingSeconds);
      if (connectingSeconds >= 40) {
        //40
        get().endCall(get().recipient);
        clearInterval(connectingInterval);
        Info("Not reachable", "Could be that contact is offline.");
        useCallHistory.getState().addCall(recipient, "missed", hasVideo, true);
        useCallHistory
          .getState()
          .addOwnCall(recipient, "unanswered", hasVideo, 0);
      }
    }, 1000);

    setTimeout(() => {
      RNCallKeep.backToForeground();
      axios.post("sendVoipPush", { recipient, hasVideo });
      //   .then((d) => {
      //   console.log("SEND VOIP PUSH RESULT", d.data);
      // });

      socket.emit("direct", recipient, {
        cachedOffer,
        name:
          useUser.getState().user.firstName +
          " " +
          useUser.getState().user.lastName,
        phone: useUser.getState().user.phone,
        hasVideo,
      });
      callInterval = setInterval(() => {
        // console.log("INTERVAL");
        // console.log(cachedOffer.candidates.length);
        socket.emit("direct", recipient, {
          cachedOffer,
          name:
            useUser.getState().user.firstName +
            " " +
            useUser.getState().user.lastName,
          phone: useUser.getState().user.phone,
          hasVideo,
        });
      }, 1000);
    }, 300);
  },

  answerCall: async () => {
    // console.log("ANSWER WITH VIDEO: ", get().videoCall);
    const ls = await getLocalStream(get().videoCall);
    InCallManager.start({ media: get().videoCall ? "video" : "audio" });
    RNCallKeep.backToForeground();
    pc.addStream(ls);

    pc.onaddstream = (event) => {
      // console.log("ON ADD STREAM IN ANSWER CALL", event);
      set({ remoteStream: event.stream });
    };

    // pc.oniceconnectionstatechange = () => {
    //   console.log("ICE state: ", pc.iceConnectionState);
    // };

    await pc.setRemoteDescription(new RTCSessionDescription(cachedOffer[0]));
    cachedOffer.forEach((c, k) => {
      if (k > 0) pc.addIceCandidate(new RTCIceCandidate(c));
    });

    const answer = await pc.createAnswer();
    await pc.setLocalDescription(answer);

    socket.emit("direct", get().recipient, {
      answer,
    });

    pc.onicecandidate = (event) => {
      // event.candidate && answerCandidates.add(event.candidate.toJSON());

      if (event.candidate) {
        console.log("Get candidates for ANSWERER");
        socket.emit("direct", get().recipient, {
          answerCandidate: event.candidate.toJSON(),
        });
      }
    };

    set({
      state: "answered",
      localStream: ls,
    });

    if (!useContacts.getState().contacts[get().recipient]) {
      useContacts.getState().checkMissing([get().recipient]);
    }

    get().callStarted();

    // console.log("ANSWER", answer);
  },
  callStarted: () => {
    durationInterval = setInterval(() => {
      // console.log("duration", _duration);
      _duration++;
      set({ duration: _duration });
    }, 1000);
  },
  declineCall: (recipient) => {
    // console.log("declineCall");
    useCallHistory
      .getState()
      .addOwnCall(recipient, "you declined", get().videoCall, 0);
    useCallHistory
      .getState()
      .addCall(recipient, "declined your", get().videoCall);
    get().stopStreamsClearCall(recipient);
    socket.emit("direct", recipient, { decline_call: true });
  },
  endCall: (recipient) => {
    // console.log("END CALL", recipient);
    get().stopStreamsClearCall(recipient);
    socket.emit("direct", recipient, { end_call: true });
  },
  // endCallExternal: (recipient) => {
  //   console.log("END CALL EXTERNAL");
  //   get().stopStreamsClearCall(recipient);
  // },
  stopStreamsClearCall: (recipient) => {
    RNCallKeep.endCall(recipient);
    RNCallKeep.endAllCalls();
    InCallManager.stop();
    if (get().localStream) {
      // console.log("CLEARING STREAMS");
      get()
        .localStream.getTracks()
        .forEach((track) => {
          track.stop();
          // console.log(track);
          get().localStream.removeTrack(track);
        });
    }

    // console.log(get().state);

    if (get().state === "active") {
      useCallHistory
        .getState()
        .addOwnCall(recipient, "outgoing", get().videoCall, _duration);
    }

    if (get().state === "answered") {
      useCallHistory
        .getState()
        .addOwnCall(recipient, "incoming", get().videoCall, _duration);
    }

    // if (peer) peer.destroy();
    cachedOffer = [];
    set({
      localStream: undefined,
      remoteStream: undefined,
      state: undefined,
      offer: undefined,
      recipient: undefined,
      videoCall: false,
      duration: 0,
    });
    pc.close();
    setTimeout(() => {
      // console.log("Recreating RTC");
      pc = new RTCPeerConnection(servers);
    }, 300);
    connectingSeconds = 0;
    _duration = 0;
    if (callInterval) clearInterval(callInterval);
    if (connectingInterval) clearInterval(connectingInterval);
    if (durationInterval) clearInterval(durationInterval);
  },
}));

export default useSocket;
