import create from "zustand";
import { persist } from "zustand/middleware";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { md5 } from "./md5";
import { Info } from "../util/Alert";
// import { Platform } from "react-native";
import * as Contacts from "expo-contacts";

let page;
let contactsObj = {};
const pageSize = 500;

function getChunked(p, showAlert, cb) {
  console.log("GETTING CONTACTS PAGE:", p);

  Contacts.getContactsAsync({
    fields: [Contacts.Fields.Name, Contacts.Fields.PhoneNumbers],
    pageSize,
    pageOffset: p * pageSize,
  })
    .then(async (d) => {
      const _phoneBook = d.data;
      const _contacts = [];

      _phoneBook.forEach((c) => {
        if (c.phoneNumbers && c.phoneNumbers.length) {
          c.phoneNumbers.forEach((p) => {
            // console.log(p);
            if (p.number && c.name) {
              // console.log(c);
              const phoneID = p.number.replace(/\D/g, "").slice(-8);
              _contacts.push({
                name: c.name,
                source: "phoneBook",
                phoneID,
                phone: p.number.replace(/\D/g, ""),
              });

              contactsObj[phoneID] = {
                name: c.name,
                phoneID,
                source: "phoneBook",
                phone: p.number.replace(/\D/g, ""),
              };
            }
          });
        }
      });

      cb({
        action: "upload-percent",
        value: "Page " + p + ", contacts:" + _contacts.length,
      });

      axios
        .post("paginatedContacts", {
          hasNextPage: d.hasNextPage,
          hasPreviousPage: d.hasPreviousPage,
          contacts: _contacts,
        })
        .then((res) => {
          if (d.hasNextPage) {
            page++;
            getChunked(page, showAlert, cb);
          } else {
            // console.log("MATCHES", res.data.matches);
            // console.log("EMAIL", res.data.emailContacts);

            if (res.data.matches) {
              Object.keys(res.data.matches).forEach((_id) => {
                // console.log(_id);
                if (contactsObj[_id]) {
                  contactsObj[res.data.matches[_id]._id] = {
                    ...res.data.matches[_id],
                    ...contactsObj[_id],
                    ...{ hasNext: true },
                  };
                  delete contactsObj[_id];
                }
              });
            }
            if (res.data.emailContacts.length) {
              res.data.emailContacts.forEach((e) => {
                if (!contactsObj[e._id]) {
                  contactsObj[e._id] = e;
                  contactsObj[e._id].hasNext = true;
                  contactsObj[e._id].source = "email";
                  contactsObj[e._id].name = e.firstName + " " + e.lastName;
                }
              });
            }
            // console.log("CONTACTS OBJ", contactsObj);
            cb({ action: "set", contactsObj });
            if (showAlert)
              Info(
                "Done!",
                "Parsed " +
                  d.total +
                  " contacts. " +
                  (p + 1) +
                  " chunks (foreground)"
              );
          }
        });
    })
    .catch((e) => {
      Info("Error", JSON.stringify(e));
      console.log(e);
    });
}

const useContacts = create(
  persist(
    (set, get) => ({
      contacts: {},
      setContacts: (contacts) => set({ contacts }),
      uploadContactsProgress: "",
      parsePhoneBook: (showAlert) => {
        const cb = (data) => {
          if (data.action === "set") {
            // console.log("SET CONTACTS FROM THREAD", data.contactsObj);
            set({ contacts: data.contactsObj, uploadContactsProgress: "" });
          }
          if (data.action === "upload-percent") {
            set({ uploadContactsProgress: data.value });
          }
        };

        page = 0;
        contactsObj = {};
        getChunked(0, showAlert, cb);
      },
      checkMissing: (uids) => {
        // console.log("CHECK MISSING STORE", uids);
        const missing = uids.filter((v) => !get().contacts[v]);
        // console.log("CHECK MISSING STORE FILTERED", missing);
        return new Promise((resolve) => {
          if (missing.length) {
            axios
              .post("getProfiles", {
                uids: missing,
              })
              .then((d) => {
                // console.log("MISSING RESULTS:", d.data);
                const tempContacts = {};
                d.data.results.forEach((c) => {
                  tempContacts[c._id] = c;
                  tempContacts[c._id].name = c.firstName + " " + c.lastName;
                  tempContacts[c._id].source = "temp";
                  tempContacts[c._id].image = c.image;
                });
                set({ contacts: { ...get().contacts, ...tempContacts } });
                resolve();
              });
          } else resolve();
        });
      },
      addEmailContact: (c) => {
        // console.log("ADD EMAIL CONTACT");
        // console.log(c, get().contacts);
        axios.post("addEmailContact", { c }).catch((e) => console.log(e));
        const tempContacts = get().contacts;
        tempContacts[c._id] = c;
        set({ contacts: tempContacts });
      },
      findByPhone: (phone) => {
        // console.log(
        //   "FIND BY PHONE, ",
        //   phone.replace(/\D/g, "").slice(-8),
        // );
        let result;
        const contacts = get().contacts;
        Object.keys(contacts).some((c) => {
          if (contacts[c].phoneID === phone.replace(/\D/g, "").slice(-8)) {
            result = contacts[c];
            return true;
          }
          return false;
        });

        return result;
      },
    }),
    { name: "contacts", getStorage: () => AsyncStorage }
  )
);

const uidFromPhone = (phone) => {
  return md5(phone.slice(-8));
};

export default useContacts;
