import { useNavigation } from "@react-navigation/core";
import React, { useState, useEffect } from "react";
import {
  View,
  Pressable,
  Modal,
  TouchableOpacity,
  Alert,
  Image,
} from "react-native";
import Text from "../components/Text";
import { useColors, useContacts, useConversations, useUser } from "../store";
import { Info } from "../util/Alert";
import ContactList from "./ContactList";
import { SwipeListView } from "react-native-swipe-list-view";
import firebase from "firebase/app";
import "firebase/firestore";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Avatar from "../components/Avatar";
import Ionicons from "@expo/vector-icons/SimpleLineIcons";
import NotificationsHandler from "../onboarding/NotificationsHandler";

dayjs.extend(relativeTime);

const Row = ({
  uid,
  lastMessage,
  lastTimestamp,
  onPress,
  unread,
  image,
  blocked,
  colors,
  contacts,
  name,
}) => (
  <View
    style={{
      backgroundColor: unread ? colors.unreadBg : colors.white,
    }}
  >
    <TouchableOpacity
      style={{
        padding: 10,
        borderBottomColor: colors.border,
        borderBottomWidth: 1,
        flexDirection: "row",
        alignItems: "center",
      }}
      onPress={() => onPress(uid)}
    >
      {name ? (
        <>
          <Avatar url={image ? image : ""} size={50} group />
          <View style={{ marginLeft: 10, flex: 1 }}>
            <Text
              style={{
                fontSize: 16,
                color: colors.main,
                fontFamily: unread ? "Lato_900Black" : "Lato_700Bold",
              }}
            >
              {name}
            </Text>
            {lastMessage ? (
              <Text style={{ opacity: 0.7 }}>
                {lastMessage.length > 30
                  ? lastMessage.slice(0, 30) + "..."
                  : lastMessage}
              </Text>
            ) : null}
          </View>
        </>
      ) : (
        <>
          <Avatar url={contacts[uid]?.image} size={50} />
          <View style={{ marginLeft: 10, flex: 1 }}>
            <Text
              style={{
                fontSize: 16,
                color: colors.main,
                fontFamily: unread ? "Lato_900Black" : "Lato_700Bold",
              }}
            >
              {contacts[uid] ? contacts[uid].name : "Unknown contact"}
            </Text>
            {blocked ? (
              <Text style={{ opacity: 0.5 }}>🚫 Blocked user.</Text>
            ) : (
              <Text style={{ opacity: 0.7 }}>
                {lastMessage.length > 30
                  ? lastMessage.slice(0, 30) + "..."
                  : lastMessage}
              </Text>
            )}
          </View>
        </>
      )}
      <View>
        <Text
          style={{
            textAlign: "right",
            fontSize: 12,
            // opacity: 0.5,
            color: colors.accent,
            fontFamily: "Lato_700Bold",
          }}
        >
          {dayjs(lastTimestamp?.seconds * 1000).fromNow()}
        </Text>
        {unread ? (
          <View
            style={{
              backgroundColor: colors.accent,
              alignSelf: "flex-end",
              alignItems: "center",
              justifyContent: "center",
              padding: 3,
              borderRadius: 15,
              width: 20,
              height: 20,
              marginTop: 6,
            }}
          >
            <Text
              style={{
                color: "#FFF",
                fontSize: 11,
                fontFamily: "Lato_700Bold",
              }}
            >
              {unread}
            </Text>
          </View>
        ) : null}
      </View>
    </TouchableOpacity>
  </View>
);

export default function Chats() {
  const navigation = useNavigation();
  const { colors } = useColors();
  const { contacts, addEmailContact } = useContacts();
  const [contactsModal, setContactsModal] = useState(false);
  const { conversations } = useConversations();
  const { uid } = useUser();
  const [groupChat, setGroupChat] = useState(false);
  const [groupSelected, setGroupSelected] = useState({});

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Pressable
          style={{ paddingHorizontal: 16 }}
          onPress={() => setContactsModal(true)}
        >
          <Ionicons
            name="note"
            size={18}
            style={{ transform: [{ rotate: "180deg" }] }}
            color={"#FFF"}
          />
        </Pressable>
      ),
    });
  }, []);

  const remove = (id) => {
    const batch = firebase.firestore().batch();
    try {
      firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .collection("conversations")
        .doc(id)
        .collection("messages")
        .get()
        .then((val) => {
          val.docs.forEach((v) => {
            // console.log(v.ref);
            batch.delete(v.ref);
          });

          batch.commit().then(() => {
            firebase
              .firestore()
              .collection("users")
              .doc(uid)
              .collection("conversations")
              .doc(id)
              .delete();
          });
        });
    } catch (e) {
      // console.log(e);
      Alert.alert("Whoops", JSON.stringify(e));
    }
  };

  const selectContact = (c) => {
    // console.log(contacts);
    if (groupChat) {
      if (groupSelected[c._id]) delete groupSelected[c._id];
      else groupSelected[c._id] = c;
      setGroupSelected({ ...groupSelected });
    } else {
      if (!contacts[c._id] || contacts[c._id].source === "temp") {
        addEmailContact(c);
      }
      if (c.hasNext) {
        setContactsModal(false);
        navigation.navigate("ChatDetail", { id: c._id });
      } else {
        Info("Invite contact", "Option is still not implemented :(");
      }
    }
  };

  const startGroupChat = () => {
    // console.log(groupSelected);
    setContactsModal(false);
    setGroupChat(false);
    setGroupSelected({});
    firebase
      .firestore()
      .collection("group-chats")
      .add({
        name: "Group Chat",
        owner: uid,
        participants: [...Object.keys(groupSelected), uid],
      })
      .then((d) => {
        const batch = firebase.firestore().batch();
        batch.set(
          firebase
            .firestore()
            .collection("users")
            .doc(uid)
            .collection("conversations")
            .doc(d.id),
          {
            name: "Group Chat",
            lastTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
            lastMessage: "",
            unread: 0,
          }
        );
        Object.keys(groupSelected).forEach((c) => {
          const conversation = firebase
            .firestore()
            .collection("users")
            .doc(groupSelected[c]._id)
            .collection("conversations")
            .doc(d.id);

          batch.set(conversation, {
            name: "Group Chat",
            lastTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
            lastMessage: "",
            unread: 0,
          });
        });
        batch.commit();
      });
  };

  return (
    <View style={{ flex: 1 }}>
      <NotificationsHandler />
      {conversations.length ? (
        <View>
          <SwipeListView
            data={conversations}
            renderItem={(item) => (
              <Row
                {...item.item}
                contacts={contacts}
                colors={colors}
                onPress={(id) => {
                  if (item.item.name)
                    navigation.navigate("ChatDetailGroup", {
                      id,
                      name: item.item.name,
                    });
                  else navigation.navigate("ChatDetail", { id });
                }}
              />
            )}
            keyExtractor={(item) => item.uid}
            renderHiddenItem={(data, rowMap) => (
              <View style={{ flex: 1, alignItems: "flex-end" }}>
                <TouchableOpacity
                  onPress={() => remove(data.item.uid)}
                  style={{
                    width: 75,
                    backgroundColor: "#FC4954",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Ionicons name="trash" size={24} color={"#FFF"} />
                </TouchableOpacity>
              </View>
            )}
            disableRightSwipe
            rightOpenValue={-75}
          />
        </View>
      ) : (
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Text>Your chat list is empty. :(</Text>
          <Pressable
            style={{
              backgroundColor: colors.main,
              paddingVertical: 10,
              paddingHorizontal: 16,
              marginTop: 10,
            }}
            onPress={() => setContactsModal(true)}
          >
            <Text style={{ color: "#FFF", fontSize: 16 }}>Start new chat</Text>
          </Pressable>
        </View>
      )}

      <Modal
        visible={contactsModal}
        presentationStyle={"pageSheet"}
        animationType={"slide"}
      >
        <View style={{ backgroundColor: colors.white, flex: 1 }}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
              padding: 5,
              marginBottom: 10,
              backgroundColor: colors.main,
            }}
          >
            <View style={{ flex: 1 }}>
              <Pressable
                onPress={() => {
                  setContactsModal(false);
                  setGroupChat(false);
                  setGroupSelected({});
                }}
                style={{ padding: 10 }}
              >
                <Text style={{ color: "#FFF" }}>Cancel</Text>
              </Pressable>
            </View>
            <Text
              style={{
                color: "#FFF",
                flex: 1,
                textAlign: "center",
                fontSize: 16,
                fontFamily: "Lato_700Bold",
              }}
            >
              Start Chat
            </Text>
            <View style={{ flex: 1, alignItems: "flex-end" }}>
              {Object.keys(groupSelected).length ? (
                <Pressable
                  onPress={() => startGroupChat(false)}
                  style={{ padding: 10 }}
                >
                  <Text style={{ color: "#FFF" }}>Next</Text>
                </Pressable>
              ) : null}
            </View>
          </View>
          {contactsModal ? (
            <ContactList
              onSelect={(c) => selectContact(c)}
              groupChat={groupChat}
              startGroupChat={() => setGroupChat(true)}
              groupSelected={groupSelected}
            />
          ) : null}
          {/* <View style={{ height: 120 }} /> */}
        </View>
      </Modal>
    </View>
  );
}
