import React, { useState } from "react";
import { Modal } from "react-native";
import SignUp from "./SignUp";
import Slider from "./Slider";

export default function Onboarding() {
  const [launchLogin, setLaunchLogin] = useState(false);
  const launchModal = () => {
    setLaunchLogin(true);
  };
  return (
    <>
      <Slider signUp={launchModal} />
      <Modal
        visible={launchLogin}
        animationType={"slide"}
        presentationStyle={"pageSheet"}
      >
        <SignUp cancel={() => setLaunchLogin(false)} />
      </Modal>
    </>
  );
}
