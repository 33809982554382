import React, { useEffect, useRef, useState } from "react";
import { DeviceEventEmitter, Pressable, View } from "react-native";
import Text from "../components/Text";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useColors, useContacts, useUser } from "../store";
import Avatar from "../components/Avatar";
import firebase from "firebase/app";
import "firebase/firestore";

export default function ProfileView() {
  const route = useRoute();
  const navigation = useNavigation();
  const { contacts } = useContacts();
  const { colors } = useColors();
  const { uid } = useUser();
  const unsubscribe = useRef();
  const id = route.params.id;
  const [isBlocked, setIsBlocked] = useState(false);

  useEffect(() => {
    navigation.setOptions({
      title: contacts[id].name,
      headerBackTitle: "",
    });

    unsubscribe.current = firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .collection("conversations")
      .doc(id)
      .onSnapshot((d) => {
        if (d.exists) {
          setIsBlocked(d.data().blocked);
        }
      });

    return () => unsubscribe.current();
  }, []);

  const toggleBlock = () => {
    DeviceEventEmitter.emit("user-block-change", !isBlocked);
    firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .collection("conversations")
      .doc(id)
      .set({ blocked: !isBlocked }, { merge: true });
  };

  return (
    <View style={{ margin: 20, alignItems: "center" }}>
      <Avatar url={contacts[id].image} size={200} />
      <Text style={{ fontSize: 22, fontWeight: "bold", marginTop: 20 }}>
        {contacts[id].name}
      </Text>
      {contacts[id].source === "phoneBook" ? (
        <Text style={{ fontSize: 18, marginTop: 5 }}>{contacts[id].phone}</Text>
      ) : null}
      {contacts[id].source === "email" ? (
        <Text style={{ fontSize: 18, marginTop: 5 }}>{contacts[id].email}</Text>
      ) : null}
      {contacts[id].source === "temp" ? (
        <Text style={{ fontSize: 18, marginTop: 5 }}>Not in your contacts</Text>
      ) : null}

      <View style={{ marginTop: 20 }}>
        <Pressable
          style={{
            backgroundColor: colors.accent,
            paddingVertical: 10,
            paddingHorizontal: 20,
            borderRadius: 20,
          }}
          onPress={() => toggleBlock()}
        >
          <Text style={{ color: "#FFF", fontSize: 18 }}>
            {isBlocked ? "UnBlock contact" : "Block contact"}
          </Text>
        </Pressable>
      </View>
    </View>
  );
}
