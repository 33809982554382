import create from "zustand";
import firebase from "firebase/app";
import "firebase/firestore";
import { persist } from "zustand/middleware";
import AsyncStorage from "@react-native-async-storage/async-storage";
import useUser from "./User";
import { useContacts } from ".";
let unsubscribe;

const useConversations = create(
  persist(
    (set) => ({
      conversations: [],
      unread: 0,
      setConversations: (conversations) => set({ conversations }),
      setUnread: (unread) => set({ unread }),
    }),
    {
      name: "conversations",
      getStorage: () => AsyncStorage,
      onRehydrateStorage: () => (state, error) => {
        const uid = useUser.getState().uid;
        console.log("UID:", uid);
        subscribeConversations(uid, state);
        useUser.subscribe(
          (uid) => {
            console.log("NEW UID", uid);
            if (uid) subscribeConversations(uid, state);
            else {
              if (unsubscribe) unsubscribe();
            }
          },
          (state) => state.uid
        );
      },
    }
  )
);

const subscribeConversations = (uid, state) => {
  // console.log("REHYDRATE CONVERSATIONS", uid);
  if (uid) {
    unsubscribe = firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .collection("conversations")
      .orderBy("lastTimestamp", "desc")
      .onSnapshot(async (snap) => {
        // console.log("OH SNAP CONVERSATIONS!");
        const convers = [];
        const uids = [];
        snap.docs.forEach((d) => {
          // console.log(d.id);
          // console.log(d.data());
          const dd = d.data();
          if (!dd.name) uids.push(d.id);
          else {
            if (dd.participants) {
              dd.participants.forEach((v) => {
                uids.push(v);
              });
            }
          }
          convers.push({
            ...dd,
            uid: d.id,
          });
        });
        // console.log(convers);
        state.setConversations(convers);
        state.setUnread(calcUnread(convers));
        setTimeout(() => {
          useContacts.getState().checkMissing(uids);
        }, 1500);
      });
  }
};

const calcUnread = (convers) => {
  // console.log("CALC", convers);
  let unread = 0;
  convers.forEach((c) => {
    unread = unread + c.unread;
  });
  return unread || null;
  // console.log("UNREAD", unread);
  // if (Platform.OS === "ios")
  //   PushNotificationIOS.setApplicationIconBadgeNumber(unread);
};

export default useConversations;
