import React, { useRef } from "react";
import {
  View,
  ActivityIndicator,
  TouchableOpacity,
  Animated,
  PanResponder,
  Linking,
  Alert,
} from "react-native";
import { Bubble, MessageText } from "react-native-gifted-chat";
import { useColors, useContacts, useUploads, useUser } from "../store";
import Text from "./Text";
import dayjs from "dayjs";
import axios from "axios";
import Ionicons from "@expo/vector-icons/Ionicons";
import { Video } from "expo-av";
import Image from "./FastImage";
import { fixUrl } from "../pages/ChatDetail";
import {
  mentionRegEx,
  replaceMentionValues,
} from "react-native-controlled-mentions";
import Clipboard from "@react-native-community/clipboard";

export default function MyBubble(props) {
  const { colors } = useColors();
  const { uid } = useUser();
  const { uploadPercents } = useUploads();
  const { contacts } = useContacts();

  const pan = useRef(new Animated.ValueXY()).current;

  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onMoveShouldSetPanResponder: () => true,
      // onPanResponderGrant: () => {
      //   pan.setOffset({
      //     x: pan.x._value,
      //     y: pan.y._value,
      //   });
      // },
      onPanResponderMove: (event, gesture) => {
        if (gesture.dx < 50 && gesture.dx > -50) {
          pan.setValue({ x: gesture.dx, y: 0 });
        }
      },
      onPanResponderRelease: (event, gesture) => {
        Animated.spring(pan.x, { toValue: 0, useNativeDriver: false }).start();
        if (gesture.dx > 30 || gesture.dx < -30) props.reply();
      },
    })
  ).current;

  const m = props.currentMessage;
  // console.log(props);
  // console.log(new Date().toTimeString);

  const calcGifSize = (m) => {
    if (!m.width || !m.height) return { width: 200, height: 200 };
    const factor = Number(m.width) / Number(m.height);
    const width = 300;
    const height = width / factor;
    return { width, height };
  };

  const renderTicks = (m) => {
    if (m.sender === uid) {
      if (props.lastRead && m.createdAt.getTime() < props.lastRead)
        return (
          <Text style={{ color: "#FFF", marginRight: 5 }}>
            <Ionicons name="checkmark-done-sharp" />
          </Text>
        );
      if (m.sent)
        return (
          <Text style={{ color: "#FFF", marginRight: 5 }}>
            <Ionicons name="checkmark-sharp" />
          </Text>
        );
      if (m.pending)
        return (
          <Text style={{ color: "#FFF", marginRight: 5 }}>
            <Ionicons name="cloud-upload-outline" />
          </Text>
        );
    }
    return null;
  };

  if (!m.type)
    return (
      <>
        {/* <Animated.View
          style={{
            transform: [{ translateX: pan.x }],
          }}
          {...panResponder.panHandlers}
        > */}
        {m.replyTo ? (
          <View
            style={{
              marginTop: 5,
              marginBottom: 5,
              backgroundColor: m.sender === uid ? "#A7CEF9" : "#CCC",
              borderRadius: 10,
              padding: 0,
              maxWidth: 300,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginHorizontal: 10,
                marginTop: 6,
              }}
            >
              <Ionicons
                name="ios-arrow-redo-sharp"
                size={16}
                color={colors.main}
              />
              <Text style={{ fontFamily: "Lato_700Bold", color: "#000" }}>
                {m.replyTo.sender === uid
                  ? "You"
                  : contacts[m.replyTo.sender]?.name}
                :
              </Text>
            </View>
            {m.replyTo.type === "image" || m.replyTo.type === "giphy" ? (
              <Image
                source={{
                  uri: fixUrl(m.replyTo.uri, true),
                }}
                style={{
                  width: 44,
                  height: 44,
                  marginLeft: 26,
                  marginBottom: 5,
                  marginTop: 5,
                }}
              />
            ) : null}

            {m.replyTo.type === "file" ? (
              <Text style={{ marginLeft: 27, marginBottom: 5, color: "#000" }}>
                <Ionicons name="document-outline" /> {m.replyTo.filename}
              </Text>
            ) : null}

            {!m.replyTo.type ? (
              <Text
                style={{
                  marginLeft: 27,
                  marginBottom: 5,
                  color: "#000",
                  marginRight: 20,
                }}
              >
                {replaceMentionValues(m.replyTo.text, ({ name }) => `@${name}`)}
              </Text>
            ) : null}

            <Bubble
              {...props}
              wrapperStyle={{
                right: {
                  backgroundColor: colors.accent,
                  width: "100%",
                  borderBottomRightRadius: 10,
                  borderBottomLeftRadius: 10,
                  borderTopRightRadius: 0,
                  borderTopLeftRadius: 0,
                },
                left: {
                  width: "100%",
                  backgroundColor: colors.border,
                  borderBottomRightRadius: 10,
                  borderBottomLeftRadius: 10,
                  borderTopRightRadius: 0,
                  borderTopLeftRadius: 0,
                },
              }}
              textStyle={{
                left: { color: colors.text, fontFamily: "Lato_400Regular" },
                right: { fontFamily: "Lato_400Regular" },
              }}
              renderTicks={renderTicks}
              renderMessageText={(textProps) => (
                <MessageText
                  {...textProps}
                  parsePatterns={(item) => [
                    {
                      pattern: mentionRegEx,
                      style: { fontFamily: "Lato_700Bold" },
                      renderText: (value) =>
                        replaceMentionValues(value, ({ name }) => `@${name}`),
                    },
                    {
                      type: "url",
                      style: {
                        textDecorationLine: "underline",
                        color: "#F00",
                      },
                    },
                  ]}
                />
              )}
            />
          </View>
        ) : (
          <Bubble
            {...props}
            wrapperStyle={{
              right: {
                backgroundColor: colors.accent,
              },
              left: {
                backgroundColor: colors.border,
              },
            }}
            textStyle={{
              left: { color: colors.text, fontFamily: "Lato_400Regular" },
              right: { fontFamily: "Lato_400Regular" },
            }}
            renderTicks={renderTicks}
            renderMessageText={(textProps) => (
              <MessageText
                {...textProps}
                parsePatterns={() => [
                  {
                    pattern: mentionRegEx,
                    style: { fontFamily: "Lato_700Bold" },
                    renderText: (value) =>
                      replaceMentionValues(value, ({ name }) => `@${name}`),
                  },
                  {
                    type: "url",
                    style: {
                      textDecorationLine: "underline",
                    },
                    onPress: (text) => {
                      Linking.openURL(text);
                    },
                    onLongPress: (text) => {
                      Clipboard.setString(text);
                      Alert.alert("Link copied!", text);
                    },
                  },
                ]}
              />
            )}
          />
        )}

        {/* {m.edited ? <Text>Edit</Text> : null} */}
        {/* </Animated.View> */}
      </>
    );
  if (m.type === "image")
    return (
      <View
        // style={{
        //   transform: [{ translateX: pan.x }],
        // }}
        // {...panResponder.panHandlers}
        style={{ backgroundColor: colors.gray, padding: 2, borderRadius: 10 }}
      >
        <TouchableOpacity
          activeOpacity={1}
          onLongPress={() => props.onLongPress(undefined, m)}
          onPress={() => props.openImage(m.uri)}
        >
          <Image
            source={{
              uri: fixUrl(m.uri, true),
            }}
            style={{
              width: 200,
              height: 200,
              borderRadius: 10,
              opacity: m.uploading ? 0.5 : 1,
            }}
          />
        </TouchableOpacity>
        <Text
          style={{
            color: "#FFF",
            fontSize: 12,
            opacity: 1,
            position: "absolute",
            top: 180,
            fontFamily: "Lato_700Bold",
            left: m.user._id === uid ? "auto" : 10,
            right: m.user._id === uid ? 10 : "auto",
          }}
        >
          {dayjs(m.createdAt).format("h:mm A")} {renderTicks(m)}
        </Text>
        {m.text ? (
          <Text style={{ marginHorizontal: 7, marginVertical: 5 }}>
            {m.text}
          </Text>
        ) : null}
        {m.uploading ? (
          <ActivityIndicator
            size="large"
            color="#FFF"
            style={{ position: "absolute", top: 95, left: 92 }}
          />
        ) : null}

        {m.uploading && uploadPercents[m._id] ? (
          <Text
            style={{
              position: "absolute",
              top: 130,
              left: 10,
              color: "#FFF",
              width: 200,
              textAlign: "center",
            }}
          >
            {uploadPercents[m._id]}%
          </Text>
        ) : null}
      </View>
    );
  if (m.type === "giphy")
    return (
      <View
      // style={{
      //   transform: [{ translateX: pan.x }],
      // }}
      // {...panResponder.panHandlers}
      >
        <TouchableOpacity
          activeOpacity={1}
          onLongPress={() => props.onLongPress(undefined, m)}
        >
          <Image
            source={{
              uri: m.uri,
            }}
            style={{
              width: calcGifSize(m).width,
              height: calcGifSize(m).height,
              borderRadius: 10,
            }}
            useNativeControls
          />
          {m.createdAt ? (
            <Text
              style={{
                color: "#666",
                fontSize: 12,
                opacity: 1,
                position: "absolute",
                bottom: 10,
                fontFamily: "Lato_700Bold",
                left: m.user._id === uid ? "auto" : 10,
                right: m.user._id === uid ? 10 : "auto",
              }}
            >
              {dayjs(m.createdAt).format("h:mm A")} {renderTicks(m)}
            </Text>
          ) : null}
        </TouchableOpacity>
      </View>
    );
  if (m.type === "video")
    return (
      <View
      // style={{
      //   transform: [{ translateX: pan.x }],
      // }}
      // {...panResponder.panHandlers}
      >
        <TouchableOpacity
          activeOpacity={1}
          onLongPress={() => props.onLongPress(undefined, m)}
          onPress={() => props.openFile(m.uri)}
        >
          <Video
            source={{
              uri: fixUrl(m.uri),
            }}
            style={{
              width: calcGifSize(m).width,
              height: calcGifSize(m).height,
              borderRadius: 10,
              opacity: m.uploading ? 0.5 : 1,
            }}
            shouldPlay={true}
          />
          {m.createdAt ? (
            <Text
              style={{
                color: "#FFF",
                fontSize: 12,
                opacity: 1,
                position: "absolute",
                bottom: 10,
                fontFamily: "Lato_700Bold",
                left: m.user._id === uid ? "auto" : 10,
                right: m.user._id === uid ? 10 : "auto",
              }}
            >
              {dayjs(m.createdAt).format("h:mm A")} {renderTicks(m)}
            </Text>
          ) : null}
          {m.uploading ? (
            <ActivityIndicator
              size="large"
              color="#FFF"
              style={{ position: "absolute", top: 95, left: 92 }}
            />
          ) : null}

          {m.uploading && uploadPercents[m._id] ? (
            <Text
              style={{
                position: "absolute",
                top: 130,
                left: 10,
                color: "#FFF",
                width: 200,
                textAlign: "center",
              }}
            >
              {uploadPercents[m._id]}%
            </Text>
          ) : null}
        </TouchableOpacity>
      </View>
    );
  if (m.type === "file")
    return (
      <View
      // style={{
      //   transform: [{ translateX: pan.x }],
      // }}
      // {...panResponder.panHandlers}
      >
        <TouchableOpacity
          activeOpacity={1}
          onLongPress={() => props.onLongPress(undefined, m)}
          onPress={() => props.openFile(m.uri)}
        >
          <View
            style={{
              backgroundColor: m.sender === uid ? colors.main : colors.gray,
              padding: 10,
              borderRadius: 10,
              opacity: m.uploading ? 0.5 : 1,
            }}
          >
            <View
              style={{
                alignItems: "center",
                marginBottom: 30,
                width: 200,
              }}
            >
              <Text
                style={{
                  color: m.sender === uid ? "#FFF" : "#333",
                  marginBottom: 10,
                }}
              >
                File message:
              </Text>
              <Ionicons
                name="document-outline"
                size={64}
                color={m.sender === uid ? "#FFF" : "#333"}
              />
              <Text style={{ color: m.sender === uid ? "#FFF" : "#333" }}>
                {m.filename}
              </Text>
            </View>
            {m.createdAt ? (
              <Text
                style={{
                  color: m.sender === uid ? "#FFF" : "#666",
                  fontSize: 12,
                  opacity: 1,
                  position: "absolute",
                  bottom: 10,
                  fontFamily: "Lato_700Bold",
                  left: m.user._id === uid ? "auto" : 10,
                  right: m.user._id === uid ? 10 : "auto",
                }}
              >
                {dayjs(m.createdAt).format("h:mm A")} {renderTicks(m)}
              </Text>
            ) : null}
            {m.uploading ? (
              <ActivityIndicator
                size="large"
                color="#FFF"
                style={{ position: "absolute", top: 60, left: 92 }}
              />
            ) : null}

            {m.uploading && uploadPercents[m._id] ? (
              <Text
                style={{
                  position: "absolute",
                  top: 130,
                  left: 10,
                  color: "#FFF",
                  width: 200,
                  textAlign: "center",
                }}
              >
                {uploadPercents[m._id]}%
              </Text>
            ) : null}
          </View>
        </TouchableOpacity>
      </View>
    );
  return null;
}
