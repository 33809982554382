import React, { useEffect } from "react";
import { Pressable } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import Ionicons from "@expo/vector-icons/SimpleLineIcons";
import Chats from "./pages/Chats";
import ChatDetail from "./pages/ChatDetail";
import Calls from "./pages/Calls";
import Profile from "./pages/Profile";
import { useCallHistory, useColors, useConversations, useUser } from "./store";
import ProfileView from "./pages/ProfileView";
import ChatDetailGroup from "./pages/ChatDetailGroup";
import Group from "./pages/Group";
import useSocket from "./store/Socket";
import Text from "./components/Text";

const ChatStack = createStackNavigator();
const ProfileStack = createStackNavigator();
const CallsStack = createStackNavigator();

function ChatStackScreen() {
  const { colors } = useColors();

  return (
    <ChatStack.Navigator>
      <ChatStack.Screen
        name="Chats"
        component={Chats}
        options={{
          cardStyle: { backgroundColor: colors.bg },
          headerStyle: { backgroundColor: colors.main },
          headerTitleStyle: {
            fontFamily: "Lato_700Bold",
            fontSize: 20,
          },
          headerTintColor: "#FFF",
        }}
      />
      <ChatStack.Screen
        name="ChatDetail"
        component={ChatDetail}
        options={{
          cardStyle: { backgroundColor: colors.bg },
          headerStyle: { backgroundColor: colors.main },
          headerTitleStyle: {
            fontFamily: "Lato_700Bold",
            fontSize: 20,
          },
          headerTintColor: "#FFF",
        }}
      />
      <ChatStack.Screen
        name="ChatDetailGroup"
        component={ChatDetailGroup}
        options={{
          cardStyle: { backgroundColor: colors.bg },
          headerStyle: { backgroundColor: colors.main },
          headerTitleStyle: {
            fontFamily: "Lato_700Bold",
            fontSize: 20,
          },
          headerTintColor: "#FFF",
        }}
      />
      <ChatStack.Screen
        name="Group"
        component={Group}
        options={{
          cardStyle: { backgroundColor: colors.bg },
          headerStyle: { backgroundColor: colors.main },
          headerTitleStyle: {
            fontFamily: "Lato_700Bold",
            fontSize: 20,
          },
          headerTintColor: "#FFF",
          headerBackTitle: " ",
        }}
      />
      <ChatStack.Screen
        name="ProfileView"
        component={ProfileView}
        options={{
          cardStyle: { backgroundColor: colors.bg },
          headerStyle: { backgroundColor: colors.main },
          headerTitleStyle: {
            fontFamily: "Lato_700Bold",
            fontSize: 20,
          },
          headerTintColor: "#FFF",
        }}
      />
    </ChatStack.Navigator>
  );
}

function ProfileStackScreen() {
  const { colors } = useColors();
  const { logOutAsk } = useUser();
  return (
    <ProfileStack.Navigator>
      <ProfileStack.Screen
        name="Profile"
        component={Profile}
        options={{
          cardStyle: { backgroundColor: colors.bg },
          headerRight: () => (
            <Pressable
              style={{ paddingHorizontal: 16 }}
              onPress={() => logOutAsk()}
            >
              <Ionicons
                name="logout"
                size={18}
                style={{ transform: [{ rotate: "180deg" }] }}
                color={"#FFF"}
              />
            </Pressable>
          ),
          headerStyle: { backgroundColor: colors.main },
          headerTitleStyle: {
            fontFamily: "Lato_700Bold",
            fontSize: 20,
          },
          headerTintColor: "#FFF",
        }}
      />
    </ProfileStack.Navigator>
  );
}

function CallsStackScreen() {
  const { colors } = useColors();
  return (
    <CallsStack.Navigator>
      <CallsStack.Screen
        name="Calls"
        component={Calls}
        options={{
          cardStyle: { backgroundColor: colors.bg },
          headerStyle: { backgroundColor: colors.main },
          headerTitleStyle: {
            fontFamily: "Lato_700Bold",
            fontSize: 20,
          },
          headerTintColor: "#FFF",
        }}
      />
    </CallsStack.Navigator>
  );
}

const Tab = createBottomTabNavigator();

export default function App() {
  const { unread } = useConversations();
  const { colors } = useColors();
  const { uid } = useUser();
  const { connect } = useSocket();
  const { subscribeCallHistory, unsubscribeCallHistory, unseen } =
    useCallHistory();

  useEffect(() => {
    connect(uid);
    subscribeCallHistory(uid);
    return onUnmount;
  }, []);

  const onUnmount = () => {
    unsubscribeCallHistory();
  };
  // console.log("TAB", unread);
  return (
    <NavigationContainer linking={true}>
      <Tab.Navigator
        screenOptions={({ route }) => ({
          tabBarIcon: ({ focused, color, size }) => {
            let iconName;

            if (route.name === "ChatsRoot") {
              iconName = "bubbles";
            } else if (route.name === "CallsRoot") {
              iconName = "phone";
            } else if (route.name === "ProfileRoot") {
              iconName = "user";
            }
            // You can return any component that you like here!
            return <Ionicons name={iconName} size={size} color={color} />;
          },
          tabBarLabel: ({ color }) => {
            let label;

            if (route.name === "ChatsRoot") {
              label = "Chats";
            } else if (route.name === "CallsRoot") {
              label = "Calls";
            } else if (route.name === "ProfileRoot") {
              label = "Profile";
            }

            return <Text style={{ fontSize: 13, color }}>{label}</Text>;
          },
          headerShown: false,
          tabBarHideOnKeyboard: false,
          tabBarActiveTintColor: "#0C63F0",
          tabBarInactiveTintColor: "gray",
          tabBarStyle: [
            {
              backgroundColor: colors.tabBar,
              display: "flex",
            },
          ],
        })}
      >
        <Tab.Screen
          name="ChatsRoot"
          component={ChatStackScreen}
          options={{ tabBarBadge: unread }}
        />
        <Tab.Screen
          name="CallsRoot"
          component={CallsStackScreen}
          options={{ tabBarBadge: unseen }}
        />
        <Tab.Screen name="ProfileRoot" component={ProfileStackScreen} />
      </Tab.Navigator>
    </NavigationContainer>
  );
}
