import create from "zustand";
import { md5 } from "./md5";
import { Confirm, Info } from "../util/Alert";
import { persist } from "zustand/middleware";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import VoipPushNotification from "react-native-voip-push-notification";
import { Platform } from "react-native";
import messaging from "@react-native-firebase/messaging";

const useUser = create(
  persist(
    (set, get) => ({
      user: undefined,
      uid: null,
      pushToken: null,
      voipToken: null,
      sioToken: null,
      setUser: (user) => set({ user }),
      setUid: (uid) => set({ uid }),
      setSioToken: (sioToken) => set({ sioToken }),
      getPushTokens: () => {
        messaging()
          .getToken()
          .then((pushToken) => {
            console.log("GOT FCM PUSH TOKEN", pushToken);
            set({ pushToken });
            axios.post("addPushToken", { pushToken });
          });

        if (Platform.OS === "ios") {
          VoipPushNotification.addEventListener("register", (voipToken) => {
            // --- send token to your apn provider server
            console.log("GOT VOIP PUSH TOKEN", voipToken);
            set({ voipToken });

            axios.post("addVoipToken", { voipToken });
            //   .then((d) => {
            //   console.log("add voip token result", d.data);
            // });
          });
          VoipPushNotification.registerVoipToken(); // --- register token
        }
      },
      /*
      setPushToken: (pushToken) => {
        console.log("SET PUSH TOKEN", pushToken);
        set({ pushToken });
        axios.post("addPushToken", { pushToken });

        if (Platform.OS === "ios") {
          VoipPushNotification.addEventListener("register", (voipToken) => {
            // --- send token to your apn provider server
            console.log("GOT VOIP PUSH TOKEN", voipToken);
            set({ voipToken });

            axios.post("addVoipToken", { voipToken });
            //   .then((d) => {
            //   console.log("add voip token result", d.data);
            // });
          });
          VoipPushNotification.registerVoipToken(); // --- register token
        }

        if (Platform.OS === "android") {
          console.log("AA");
          // messaging()
          //   .getToken()
          //   .then((token) => {
          //     console.log("GOT ANDROID VOIP PUSH TOKEN", token);
          //   });
        }
      },*/
      logOutAsk: () => {
        Confirm("Are you sure?", "")
          .then(() => {
            if (get().pushToken) {
              axios.post("removePushToken", {
                pushToken: get().pushToken,
                voipToken: get().voipToken,
              });
              // .then((d) => {
              //   console.log("LOG OUT API RESULT: ", d.data);
              // });
            }

            delete axios.defaults.headers.uid;
            set({
              user: null,
              uid: null,
              pushToken: null,
              sioToken: null,
              voipToken: null,
            });
          })
          .catch(() => {});
      },
      logIn: (uid, phone) => {
        // const uid = uidFromPhone(phone);
        axios.defaults.headers.uid = uid;

        axios
          .get("profile")
          .then((d) => {
            console.log("LOGIN GET PROFILE", d.data);
            if (d.data.profile) {
              set({ user: d.data.profile, uid });
            } else {
              set({ user: { phone }, uid });
            }
          })
          .catch((e) => {
            console.log(e);
            Info("Network error.", "Could not get profile.");
          });
      },
      save: (profile) => {
        console.log("SAVE", profile);
        // console.log("SIO TOKEN", get().sioToken);
        axios
          .post(
            "https://signinonce.com/api/updateUser",
            {
              token: get().sioToken,
              application: "Next",
              first_name: profile.firstName,
              last_name: profile.lastName,
              email: profile.email,
              phone: profile.phone,
            },
            { headers: { portal_id: 8 } }
          )
          .then((d) => {
            // console.log(d.data);
          })
          .catch((e) => {
            // console.log(e);
          });

        return new Promise((resolve) => {
          axios
            .post("profile", { profile })
            .then((d) => {
              if (d.data.success) {
                set({ user: profile });
              } else {
                Info("Unknown error.", "Could not save profile.");
              }
              resolve();
            })
            .catch((e) => {
              Info("Network error.", "Could not save profile.");
              resolve();
            });
        });
      },
      saveImage: (image) => {
        axios
          .post("profile", { profile: { image } })
          .then((d) => {
            if (!d.data.success) {
              Info("Unknown error.", "Could not save image.");
            }
          })
          .catch((e) => {
            Info("Network error.", "Could not save image.");
          });
      },
    }),
    {
      name: "user",
      getStorage: () => AsyncStorage,
      onRehydrateStorage: () => (state, error) => {
        // console.log(error);
        if (state.uid) {
          axios.defaults.headers.uid = state.uid;

          axios
            .get("profile")
            .then((d) => {
              // console.log("HYDRATE", d.data);
              if (d.data.profile) {
                state.setUser(d.data.profile);
              }
            })
            .catch((e) => {
              console.log(state);
              // Info("Network error.", "Could not get profile (rehydrate).");
            });
        } else {
          state.setUser(null);
          state.setUid(null);
        }
      },
    }
  )
);

const uidFromPhone = (phone) => {
  return md5(phone.slice(-8));
};

export default useUser;
