import create from "zustand";
const theme = {
  light: {
    bg: "#F0f0f0",
    white: "#FFF",
    gray: "#DDD",
    text: "#000",
    main: "#2283EC",
    red: "#CC3300",
    green: "#4caf50",
    unreadBg: "#E5F1FF",
    border: "#DDD",
    input: "#FFF",
    inputText: "#333",
    accent: "#0C63F0",
    tabBar: "#FFF",
  },
  dark: {
    bg: "#111",
    white: "#000",
    gray: "#333",
    text: "#FFF",
    main: "#2283EC",
    red: "#CC3300",
    green: "#4caf50",
    unreadBg: "#062f5d",
    border: "#333",
    input: "#333",
    inputText: "#FFF",
    accent: "#0C63F0",
    tabBar: "#222",
  },
};

const useColors = create((set) => ({
  colors: theme.light,
  setTheme: (t) => set({ colors: theme[t] }),
}));

export default useColors;
