import React, { useEffect, useRef, useState } from "react";
import {
  View,
  TouchableOpacity,
  DeviceEventEmitter,
  Platform,
  Modal,
  ActivityIndicator,
  Image,
  Pressable,
  KeyboardAvoidingView,
  Keyboard,
} from "react-native";
import Text from "../components/Text";
import Clipboard from "@react-native-community/clipboard";
import { useNavigation, useRoute } from "@react-navigation/native";
import {
  useColors,
  useContacts,
  useSocket,
  useUploads,
  useUser,
} from "../store";
import {
  GiftedChat,
  Composer,
  LoadEarlier,
  Send,
} from "react-native-gifted-chat";
import Avatar from "../components/Avatar";
import firebase from "firebase/app";
import "firebase/firestore";
import Ionicons from "@expo/vector-icons/Ionicons";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import axios from "axios";
import * as DocumentPicker from "expo-document-picker";
import { v4 as uuidv4 } from "uuid";
import GiphyPicker from "../components/GiphyPicker";
import MyBubble from "../components/Bubble";
import { useActionSheet } from "@expo/react-native-action-sheet";
import ImageView from "../components/ImageView";
import { WebView } from "react-native-webview";
import { SafeAreaView } from "react-native-safe-area-context";
import { throttle } from "lodash";
import ImagesPreSend from "../components/ImagesPreSend";
import ImagePicker from "../components/ImagePickerShim";
import dayjs from "dayjs";
import { humanTimeFormat } from "./CallScreen";

const renderAvatar = (navigation, profile) => {
  // console.log(profile);
  if (profile) {
    return (
      <TouchableOpacity
        onPress={() => navigation.navigate("ProfileView", { id: profile._id })}
      >
        <Avatar url={profile?.image} size={36} />
      </TouchableOpacity>
    );
  }
  return <Avatar url={null} size={36} />;
};

export const fixUrl = (u, tn) => {
  if (!u) return "";
  if (tn) {
    if (u.startsWith("uploads"))
      return (
        axios.defaults.baseURL.replace("api/", "") +
        u.split(".jpg")[0] +
        "_tn.jpg"
      );
    else return u;
  } else {
    return u.startsWith("uploads")
      ? axios.defaults.baseURL.replace("api/", "") + u
      : u;
  }
};
let keyboardShowListener;
let keyboardHideListener;

export default function ChatDetail() {
  const route = useRoute();
  const navigation = useNavigation();
  const recipient = route.params.id;
  const unsubscribe = useRef();
  const unsubscribe2 = useRef();
  const [messages, setMessages] = useState([]);
  const [endReached, setEndReached] = useState(false);
  const [loadingEarlier, setLoadingEarlier] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [isBlockedByThisUser, setIsBlockedByThisUser] = useState(false);
  const { uid, user } = useUser();
  const { colors } = useColors();
  const { contacts, checkMissing } = useContacts();
  const { resizeAndUpload, upload } = useUploads();
  const [showGiphy, setShowGiphy] = useState(false);
  const [replyTo, setReplyTo] = useState(null);
  const [editMessage, setEditMessage] = useState(null);
  const [messageText, setMessageText] = useState("");
  const { showActionSheetWithOptions } = useActionSheet();
  const [chatImages, setChatImages] = useState([]);
  const [imageKey, setImageKey] = useState(-1);
  const [showFile, setShowFile] = useState(null);
  const [lastRead, setLastRead] = useState(0);
  const typingDebounced = useRef();
  const [pickedImages, setPickedImages] = useState([]);
  const { meTyping, call, typingData } = useSocket();
  const [paddingBottom, setPaddingBottom] = useState(0);

  const [admin] = useState(
    uid === "0100e59a-cf88-4b45-93bc-dbd0029cc8f4" ||
      uid === "e0ed70a1-c239-4884-bed1-959824e96188"
  );

  useEffect(() => {
    if (!contacts[recipient]) {
      checkMissing([recipient]);
    }
    if (!unsubscribe.current) {
      loadAndListen();
    }

    typingDebounced.current = throttle(() => {
      typingTo(recipient);
    }, 1000);

    firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .collection("conversations")
      .doc(recipient)
      .get()
      .then((d) => {
        setIsBlockedByThisUser(d.data()?.blocked);
      });

    DeviceEventEmitter.addListener("user-block-change", userBlockChange);

    keyboardShowListener = Keyboard.addListener("keyboardDidShow", (event) => {
      // console.log("Keyboard did show", event.endCoordinates.height);
      setPaddingBottom(event.endCoordinates.height + 50);
    });

    keyboardHideListener = Keyboard.addListener("keyboardDidHide", () => {
      setPaddingBottom(0);
    });

    return onUnmount;
  }, []);

  useEffect(() => {
    if (contacts[recipient]) {
      navigation.setOptions({
        title: contacts[recipient].name,
        headerRight: () => (
          <View style={{ flexDirection: "row" }}>
            <Pressable
              style={{ paddingHorizontal: 7, paddingVertical: 5 }}
              onPress={() => call(recipient, false)}
            >
              <Ionicons
                name="call-outline"
                size={18}
                style={{ transform: [{ rotate: "180deg" }] }}
                color={"#FFF"}
              />
            </Pressable>
            <Pressable
              style={{ paddingHorizontal: 15, paddingVertical: 5 }}
              onPress={() => call(recipient, true)}
            >
              <Ionicons
                name="videocam-outline"
                size={18}
                style={{ transform: [{ rotate: "180deg" }] }}
                color={"#FFF"}
              />
            </Pressable>
          </View>
        ),
      });
    }
  }, [contacts]);

  const onUnmount = () => {
    // console.log("CALLING ONUNMOUNT");
    unsubscribe.current();
    unsubscribe2.current();
    DeviceEventEmitter.removeListener("user-block-change", userBlockChange);
    keyboardHideListener.remove();
    keyboardShowListener.remove();
    // peer.current.destroy();
  };

  const userBlockChange = (val) => {
    setIsBlockedByThisUser(val);
  };

  const loadAndListen = async () => {
    unsubscribe.current = firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .collection("conversations")
      .doc(recipient)
      .collection("messages")
      .orderBy("createdAt", "asc")
      .limitToLast(30)
      .onSnapshot({ includeMetadataChanges: true }, (snap) => {
        let arr = [];
        snap.docs.forEach((m) => {
          const message = m.data();
          // console.log(m.metadata);
          arr.unshift({
            ...message,
            ...{
              _id: m.id,
              text: message.text,
              createdAt: message.createdAt
                ? message.createdAt.toDate()
                : new Date(),
              // uri: message.uri
              //   ? message.uri.startsWith("uploads")
              //     ? axios.defaults.baseURL +
              //       "../" +
              //       message.uri.split(".jpg")[0] +
              //       "_tn.jpg"
              //     : message.uri
              //   : "",
              sent: !m.metadata.hasPendingWrites,
              pending: m.metadata.hasPendingWrites,
              user: {
                _id: message.sender,
                name:
                  message.sender === uid
                    ? user.firstName + " " + user.lastName
                    : contacts[route.params.id]?.name,
                avatar:
                  message.sender === uid
                    ? user.image
                    : contacts[route.params.id]?.image,
              },
            },
          });
        });
        setMessages(arr);
        firebase
          .firestore()
          .collection("users")
          .doc(uid)
          .collection("conversations")
          .doc(recipient)
          .set(
            {
              unread: 0,
              lastRead: firebase.firestore.FieldValue.serverTimestamp(),
            },
            { merge: true }
          );
      });

    unsubscribe2.current = firebase
      .firestore()
      .collection("users")
      .doc(recipient)
      .collection("conversations")
      .doc(uid)
      .onSnapshot((d) => {
        if (d.exists) {
          setLastRead(d.data()?.lastRead?.toDate().getTime());
          setIsBlocked(d.data()?.blocked);
        }
      });
  };

  const loadEarlier = () => {
    console.log("LOAD EARLIER");
    setLoadingEarlier(true);
    firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .collection("conversations")
      .doc(recipient)
      .collection("messages")
      .orderBy("createdAt", "asc")
      .where("createdAt", "<", messages[messages.length - 1].createdAt)
      .limitToLast(10)
      .get()
      .then((snap) => {
        setLoadingEarlier(false);
        if (snap.size) {
          let newMessages = [];
          snap.docs.forEach((m) => {
            const message = m.data();
            newMessages.unshift({
              _id: m.id,
              text: message.text,
              createdAt: message.createdAt
                ? message.createdAt.toDate()
                : new Date(),
              // uri: message.uri
              //   ? message.uri.startsWith("uploads")
              //     ? axios.defaults.baseURL +
              //       "../" +
              //       message.uri.split(".jpg")[0] +
              //       "_tn.jpg"
              //     : message.uri
              //   : "",
              sent: !m.metadata.hasPendingWrites,
              pending: m.metadata.hasPendingWrites,
              user: {
                _id: message.sender,
                name:
                  message.sender === uid
                    ? user.firstName + " " + user.lastName
                    : contacts[route.params.id]?.name,
                avatar:
                  message.sender === uid
                    ? user.image
                    : contacts[route.params.id]?.image,
              },
            });
          });
          setMessages([...messages, ...newMessages]);
        } else {
          setEndReached(true);
        }
      });
  };

  const sendText = () => {
    if (!messageText) return false;
    if (editMessage) {
      const message_id = editMessage._id;
      delete editMessage.pending;
      delete editMessage.sent;
      delete editMessage.user;
      delete editMessage.uri;
      delete editMessage._id;
      editMessage.text = messageText;
      editMessage.edited = true;
      console.log(editMessage);

      firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .collection("conversations")
        .doc(recipient)
        .collection("messages")
        .doc(message_id)
        .set(editMessage);

      firebase
        .firestore()
        .collection("users")
        .doc(recipient)
        .collection("conversations")
        .doc(uid)
        .collection("messages")
        .doc(message_id)
        .set(editMessage);

      setMessageText("");
      setEditMessage(null);
    } else {
      const message = {};
      message.sender = uid;
      message.text = messageText;
      setMessageText("");
      message.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      executeSend(message, uuidv4());
    }
  };

  const executeSend = (message, message_id) => {
    console.log("EXECUTE SEND", message);
    let body = message.type ? message.type + " message" : message.text;
    if (replyTo) body = "Replied: " + body;

    axios.post("newMessage", {
      recipient,
      body,
    });

    if (replyTo) {
      message.replyTo = replyTo;
      delete message.replyTo.sent;
      delete message.replyTo.pending;
      delete message.replyTo.sent;
      delete message.replyTo.user;
      delete message.replyTo.edited;
      setReplyTo(null);
    }

    let batch = firebase.firestore().batch();

    let ownMessages = firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .collection("conversations")
      .doc(recipient)
      .collection("messages")
      .doc(message_id);

    batch.set(ownMessages, message);

    let ownConversation = firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .collection("conversations")
      .doc(recipient);

    batch.set(
      ownConversation,
      {
        lastTimestamp: message.createdAt,
        lastMessage: message.type ? message.type + " message" : message.text,
      },
      { merge: true }
    );

    if (!isBlocked) {
      let recMessages = firebase
        .firestore()
        .collection("users")
        .doc(recipient)
        .collection("conversations")
        .doc(uid)
        .collection("messages")
        .doc(message_id);

      batch.set(recMessages, message);

      let recConversation = firebase
        .firestore()
        .collection("users")
        .doc(recipient)
        .collection("conversations")
        .doc(uid);

      batch.set(
        recConversation,
        {
          lastTimestamp: message.createdAt,
          lastMessage: message.type ? message.type + " message" : message.text,
          unread: firebase.firestore.FieldValue.increment(1),
        },
        { merge: true }
      );
    }

    batch.commit();
  };

  const deleteMessage = async (m) => {
    // console.log("DELETE", m);
    await firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .collection("conversations")
      .doc(recipient)
      .collection("messages")
      .doc(m._id)
      .delete();

    if (m.user._id === uid) {
      await firebase
        .firestore()
        .collection("users")
        .doc(recipient)
        .collection("conversations")
        .doc(uid)
        .collection("messages")
        .doc(m._id)
        .delete();
    }
  };

  const _onLongPress = (c, m) => {
    let showSheet;
    if (c && c.actionSheet) {
      showSheet = c.actionSheet().showActionSheetWithOptions;
    } else showSheet = showActionSheetWithOptions;

    if (!m.type && m.sender === uid) {
      const options = [
        "Reply",
        "Edit",
        "Copy Text",
        "Delete Message",
        "Cancel",
      ];

      const cancelButtonIndex = options.length - 1;
      showSheet(
        {
          options,
          cancelButtonIndex,
        },
        (buttonIndex) => {
          switch (buttonIndex) {
            case 0:
              //reply
              setReplyTo(m);
              break;
            case 1:
              //edit
              setEditMessage(m);
              setMessageText(m.text);
              break;
            case 2:
              Clipboard.setString(m.text);
              break;
            case 3:
              deleteMessage(m);
              break;
          }
        }
      );
    }

    if (!m.type && m.sender !== uid) {
      const options = ["Reply", "Copy Text", "Delete Message", "Cancel"];

      const cancelButtonIndex = options.length - 1;
      showSheet(
        {
          options,
          cancelButtonIndex,
        },
        (buttonIndex) => {
          switch (buttonIndex) {
            case 0:
              //reply
              setReplyTo(m);
              break;
            case 1:
              Clipboard.setString(m.text);
              break;
            case 2:
              deleteMessage(m);
              break;
          }
        }
      );
    }
    if (m.type) {
      showSheet(
        {
          options: ["Reply", "Delete Message", "Cancel"],
          cancelButtonIndex: 2,
        },
        (buttonIndex) => {
          switch (buttonIndex) {
            case 0:
              //reply
              setReplyTo(m);
              break;
            case 1:
              deleteMessage(m);
              break;
          }
        }
      );
    }
  };

  const pickFromLibrary = async () => {
    ImagePicker.openPicker({
      multiple: true,
      compressImageMaxWidth: 1000,
      compressImageMaxHeight: 800,
    }).then((images) => {
      console.log(images);
      setPickedImages(images);
    });
  };

  const pickFile = async () => {
    const message_id = uuidv4();
    let result = await DocumentPicker.getDocumentAsync({
      copyToCacheDirectory: false,
    });
    console.log(result);
    if (!result.cancelled) {
      let type = "file";
      if (result.name.toLowerCase().endsWith(".jpg")) {
        type = "image";
        resizeAndUpload(result.uri, message_id, uploadDone);
      } else if (
        result.name.toLowerCase().endsWith(".mp4") ||
        result.name.toLowerCase().endsWith(".mov")
      ) {
        type = "video";
        upload(result.uri, message_id, uploadDone, type, result.name);
      } else {
        upload(result.uri, message_id, uploadDone, type, result.name);
      }

      firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .collection("conversations")
        .doc(recipient)
        .collection("messages")
        .doc(message_id)
        .set({
          uploading: true,
          uri: result.uri,
          sender: uid,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          pending: true,
          type,
          filename: result.name,
        });
    }
  };

  const uploadDone = (message_id, url, type, filename, description) => {
    console.log("UPLOAD DONE CHAT DETAIL", message_id, url);
    const message = {
      uri: url,
      sender: uid,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      type,
      text: description || "",
      pending: true,
      filename: filename || "",
    };
    executeSend(message, message_id);
  };

  const sendGif = (url, width, height) => {
    console.log("SEND GIF", url);
    const message = {
      uri: url,
      sender: uid,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      type: "giphy",
      text: "",
      width,
      height,
      sent: true,
    };
    executeSend(message, uuidv4());
  };

  const renderReplyTo = () => {
    if (replyTo)
      return (
        <View
          style={{
            padding: 10,
            backgroundColor: colors.gray,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Ionicons name="ios-arrow-redo-sharp" size={22} color={colors.main} />
          {replyTo.type === "image" || replyTo.type === "giphy" ? (
            <Image
              source={{
                uri: fixUrl(replyTo.uri, true),
              }}
              style={{ width: 44, height: 44 }}
            />
          ) : null}
          <View style={{ flex: 1, marginHorizontal: 10 }}>
            <Text style={{ fontFamily: "Lato_700Bold" }}>
              {replyTo.sender === uid ? "You" : contacts[recipient].name}:
            </Text>
            {replyTo.text ? (
              <Text numberOfLines={1}>{replyTo.text}</Text>
            ) : null}
            {replyTo.type ? <Text>{replyTo.type} message</Text> : null}
          </View>
          <TouchableOpacity onPress={() => setReplyTo(null)}>
            <Ionicons name="close" size={22} />
          </TouchableOpacity>
        </View>
      );
    return null;
  };

  const openImage = (u) => {
    // console.log(u);
    let images = [];
    let key = -1;
    messages.forEach((m, k) => {
      if (m.type === "image" && m.uri)
        images.push({
          uri: fixUrl(m.uri),
        });
    });
    images.some((i, k) => {
      if (i.uri.indexOf(u) > -1) {
        key = k;
        return true;
      }
      return false;
    });
    // console.log(images);
    // console.log(key);
    setChatImages(images);
    setImageKey(key);
  };

  const openFile = (u) => {
    if (Platform.OS === "web") {
      window.open(fixUrl(u), "_blank").focus();
    } else {
      setShowFile(u);
    }
  };

  const sendMultiImages = (descriptions) => {
    [...pickedImages].forEach((i, k) => {
      // console.log(i.path, i.width, i.height);
      // console.log(descriptions[k]);

      const message_id = uuidv4();

      // console.log(i);
      if (i.mime.startsWith("image")) {
        resizeAndUpload(i.path, message_id, uploadDone, descriptions[k]);
      } else {
        upload(
          i.path,
          message_id,
          uploadDone,
          "video",
          "video-file",
          descriptions[k]
        );
      }
      firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .collection("conversations")
        .doc(recipient)
        .collection("messages")
        .doc(message_id)
        .set({
          uploading: true,
          uri: i.path,
          sender: uid,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          pending: true,
          type: "video",
          text: descriptions[k] || "",
        });
    });
    setPickedImages([]);
  };

  const renderSystemMessage = (message) => {
    return (
      <View
        style={{
          width: "90%",
          maxWidth: 300,
          backgroundColor: colors.white,
          alignSelf: "center",
          marginBottom: 20,
          padding: 10,
          borderRadius: 10,
        }}
      >
        <Text
          style={{
            textAlign: "center",
            opacity: 0.5,
            color: message.type === "missed" ? colors.red : colors.text,
          }}
        >
          [
          {dayjs(message.createdAt)
            .subtract(message.duration ? message.duration : 0, "seconds")
            .format("HH:mm")}
          ] {message.type} {message.hasVideo ? " video call " : " audio call "}
          {message.duration ? (
            <Text>({humanTimeFormat(message.duration)})</Text>
          ) : null}
        </Text>
      </View>
    );
  };

  return (
    <>
      {/* <WebRTC recipient={recipient} /> */}
      <GiftedChat
        messages={messages}
        placeholder="Enter message..."
        textInputProps={{
          autoCapitalize: "sentences",
        }}
        onLongPress={(c, m) => _onLongPress(c, m)}
        renderBubble={(props) => (
          <MyBubble
            {...props}
            reply={() => setReplyTo(props.currentMessage)}
            openImage={(u) => openImage(u)}
            openFile={(u) => openFile(u)}
            lastRead={lastRead}
          />
        )}
        user={{
          _id: uid,
          avatar: user?.image || "",
          name: user?.firstName + " " + user.lastName,
        }}
        scrollToBottom
        // isTyping={true}
        renderFooter={() =>
          typingData[recipient] ? (
            <View style={{ marginLeft: 60, marginVertical: 20 }}>
              <Image
                source={require("../../assets/typing.gif")}
                style={{ width: 16, height: 11 }}
              />
            </View>
          ) : null
        }
        renderSystemMessage={({ currentMessage }) =>
          renderSystemMessage(currentMessage)
        }
        renderChatFooter={renderReplyTo}
        renderInputToolbar={(props) => {
          if (contacts[recipient]?.deletedAccount)
            return (
              <View style={{ padding: 5, width: "100%" }}>
                <Text style={{ textAlign: "center" }}>
                  {contacts[recipient]?.name} deleted theirs account.
                </Text>
              </View>
            );
          //ako zelimo da current user zna da je blokiran odkomentarisati ovo:
          // else if (isBlocked)
          // 	return (
          // 		<View style={{ padding: 5, width: "100%" }}>
          // 			<Text style={{ textAlign: "center" }}>
          // 				{recipientInfo.firstName+ " "+user.lastName} vas je blokirao.
          // 			</Text>
          // 		</View>
          // 	);
          else if (isBlockedByThisUser)
            return (
              <View
                style={{
                  padding: 5,
                  width: "100%",
                }}
              >
                <Text style={{ textAlign: "center" }}>
                  You have blocked {contacts[recipient]?.name}.
                </Text>
              </View>
            );
          else
            return (
              <View>
                <View
                  style={{
                    flexDirection: "row",
                    backgroundColor: colors.white,
                    borderTopColor: colors.gray,
                    borderTopWidth: 1,
                  }}
                >
                  {Platform.OS === "web" ? (
                    <>
                      <TouchableOpacity
                        style={{ padding: 8 }}
                        onPress={() => pickFile()}
                      >
                        <Ionicons
                          name={"attach"}
                          size={26}
                          color={colors.main}
                        />
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={{ padding: 8 }}
                        onPress={() => setShowGiphy(true)}
                      >
                        <MaterialIcons
                          name={"gif"}
                          size={26}
                          color={colors.main}
                        />
                      </TouchableOpacity>
                    </>
                  ) : null}
                  <Composer
                    {...props}
                    textInputStyle={{
                      fontFamily: "Lato_400Regular",
                      padding: 10,
                      borderRadius: 6,
                      color: colors.text,
                    }}
                    multiline
                    textInputProps={{
                      value: messageText,
                      onChangeText: (e) => {
                        setMessageText(e);
                        meTyping(recipient);
                        // typingDebounced.current();
                      },
                      autoCapitalize: "sentences",
                    }}
                  />
                  <TouchableOpacity onPress={() => sendText()}>
                    <Ionicons
                      name={"send"}
                      color={colors.main}
                      style={{ padding: 10 }}
                      size={24}
                    />
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    height: 44,
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: colors.white,
                    paddingLeft: 10,
                  }}
                >
                  <TouchableOpacity
                    style={{ padding: 8 }}
                    onPress={() => pickFromLibrary()}
                  >
                    <Ionicons name={"images"} size={26} color={colors.main} />
                  </TouchableOpacity>
                  {/* <TouchableOpacity
                    style={{ padding: 8 }}
                    onPress={() => pickVideo("")}
                  >
                    <Ionicons name={"videocam"} size={26} color={colors.main} />
                  </TouchableOpacity> */}
                  <TouchableOpacity
                    style={{ padding: 8 }}
                    onPress={() => setShowGiphy(true)}
                  >
                    <MaterialIcons name={"gif"} size={26} color={colors.main} />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{ padding: 8 }}
                    onPress={() => pickFile()}
                  >
                    <Ionicons name={"attach"} size={26} color={colors.main} />
                  </TouchableOpacity>
                </View>
              </View>
            );
        }}
        alwaysShowSend
        textInputStyle={{
          backgroundColor: colors.input,
          borderRadius: 20,
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 15,
          color: colors.inputText,
        }}
        infiniteScroll
        renderAvatar={(props) => renderAvatar(navigation, contacts[recipient])}
        onLoadEarlier={() => loadEarlier()}
        loadEarlier
        bottomOffset={Platform.OS === "android" ? -paddingBottom : 34}
        keyboardShouldPersistTaps={"never"}
        renderLoadEarlier={(props) => {
          if (endReached || messages.length < 30) return null;
          else {
            if (loadingEarlier)
              return (
                <View style={{ margin: 10 }}>
                  <ActivityIndicator color="#333" />
                </View>
              );
            return <LoadEarlier {...props} />;
          }
        }}
      />
      <GiphyPicker
        show={showGiphy}
        close={() => setShowGiphy(false)}
        pick={sendGif}
      />
      <ImagesPreSend
        images={pickedImages}
        close={() => setPickedImages([])}
        send={(descriptions) => sendMultiImages(descriptions)}
      />
      {imageKey !== -1 ? (
        <ImageView
          images={chatImages}
          imageIndex={imageKey}
          visible={imageKey !== -1}
          onRequestClose={() => setImageKey(-1)}
        />
      ) : null}
      <Modal visible={showFile ? true : false} animationType="slide">
        <SafeAreaView
          style={{
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <View style={{ flex: 1 }} />
          <TouchableOpacity
            onPress={() => {
              setShowFile(null);
            }}
            style={{ flex: 1, alignItems: "flex-end", paddingHorizontal: 20 }}
          >
            <Text
              style={{
                fontSize: 16,
                color: colors.main,
              }}
            >
              Done
            </Text>
          </TouchableOpacity>
        </SafeAreaView>

        {showFile ? (
          <WebView
            source={{
              uri: fixUrl(showFile),
            }}
            style={{ marginTop: 20 }}
          />
        ) : null}
      </Modal>
    </>
  );
}
