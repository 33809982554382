import React from "react";
import { View, Text, Pressable } from "react-native";
import { useColors } from "../store";
import ProfileEdit from "./ProfileEdit";

export default function Profile() {
  const { colors } = useColors();
  return (
    <View style={{ flex: 1 }}>
      <ProfileEdit />
    </View>
  );
}
