import React, { useState, useEffect } from "react";
import {
  Pressable,
  View,
  ActivityIndicator,
  Platform,
  KeyboardAvoidingView,
  ScrollView,
} from "react-native";
import Text from "../components/Text";
import TextInput from "../components/TextInput";
import * as ImagePicker from "expo-image-picker";
import * as ImageManipulator from "expo-image-manipulator";
import Avatar from "../components/Avatar";
import { useActionSheet } from "@expo/react-native-action-sheet";
import FormData from "form-data";
import axios from "axios";
import { useColors, useUser } from "../store";
import Updates from "../components/Updates";

function DataURIToBlob(dataURI) {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
}

export default function ProfileEdit() {
  const { user, uid, save, saveImage } = useUser();
  const [ajax, setAjax] = useState(false);
  const { colors } = useColors();
  const [firstName, setFirstName] = useState(user.firstName || "");
  const [lastName, setLastName] = useState(user.lastName || "");
  const [email, setEmail] = useState(user.email || "");
  const [image, setImage] = useState(user.image || "");
  const [uploading, setUploading] = useState(false);
  const { showActionSheetWithOptions } = useActionSheet();

  useEffect(() => {
    // console.log("PROFILE EDIT", user);
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setEmail(user.email);
    setImage(user.image);
  }, [user]);

  const _save = async () => {
    setAjax(true);
    await save({
      firstName,
      lastName,
      email,
      phone: user.phone,
      phoneID: user.phone.slice(-8),
      complete: true,
      image,
    });
    setAjax(false);
  };

  const pickImage = async () => {
    if (Platform.OS !== "web") {
      showActionSheetWithOptions(
        {
          options: ["Camera", "Library", "Cancel"],
          cancelButtonIndex: 2,
          title: "Select image from:",
        },
        async (k) => {
          if (k === 0) {
            const { status } =
              await ImagePicker.requestCameraPermissionsAsync();
            if (status !== "granted") {
              alert(
                "Sorry, we need camera roll permissions to make this work!"
              );
            }
            let result = await ImagePicker.launchCameraAsync({
              allowsEditing: true,
              aspect: [4, 4],
              quality: 1,
            });
            // console.log(result);

            if (!result.cancelled) {
              upload(result.uri);
            }
          }
          if (k === 1) {
            const { status } =
              await ImagePicker.requestMediaLibraryPermissionsAsync();
            if (status !== "granted") {
              alert(
                "Sorry, we need camera roll permissions to make this work!"
              );
            }

            let result = await ImagePicker.launchImageLibraryAsync({
              mediaTypes: ImagePicker.MediaTypeOptions.All,
              allowsEditing: true,
              aspect: [4, 4],
              quality: 1,
            });
            // console.log(result);

            if (!result.cancelled) {
              upload(result.uri);
            }
          }
        }
      );
    } else {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });
      // console.log(result);

      if (!result.cancelled) {
        upload(result.uri);
      }
    }
  };

  const upload = async (input) => {
    setUploading(true);
    const result = await ImageManipulator.manipulateAsync(
      input,
      [{ resize: { width: 800 } }],
      { compress: 0.6, format: ImageManipulator.SaveFormat.JPEG }
    );
    const url = result.uri;

    setImage(url);

    const formData = new FormData();
    if (Platform.OS === "web") {
      formData.append("file", DataURIToBlob(url), "profile-photo.jpg");
    } else {
      formData.append("file", {
        type: "image/jpeg",
        name: "profile-photo.jpg",
        uri: Platform.OS === "android" ? url : url.replace("file://", ""),
      });
    }
    axios
      .post("upload", formData)
      .then((d) => {
        setUploading(false);
        if (!d.data.url) {
          alert("Error uploading picture.");
          setImage("");
        } else saveImageUrl(d.data.url);
      })
      .catch(() => {
        alert("Error uploading picture.");
        setUploading(false);
      });
  };

  const saveImageUrl = (image) => {
    setImage(image);
    saveImage(image);
  };

  return (
    <KeyboardAvoidingView behavior="position">
      <ScrollView style={{ padding: 20 }}>
        <Pressable
          onPress={pickImage}
          style={{ alignItems: "center" }}
          disabled={uploading}
        >
          <Avatar url={image || user.image} size={100} uploading={uploading} />

          <Text style={{ opacity: 0.5, fontSize: 13, marginTop: 5 }}>
            Click to change
          </Text>
        </Pressable>
        <Text style={{ marginTop: 20 }}>First Name:</Text>
        <TextInput onChangeText={(e) => setFirstName(e)} value={firstName} />
        <Text style={{ marginTop: 20 }}>Last Name:</Text>
        <TextInput onChangeText={(e) => setLastName(e)} value={lastName} />
        <Text style={{ marginTop: 20 }} keyboardType="email-address">
          Email:
        </Text>
        <TextInput
          keyboardType={"email-address"}
          autoCapitalize={"none"}
          onChangeText={(e) => setEmail(e)}
          value={email}
        />
        <Text style={{ marginTop: 20 }}>UID:</Text>
        <TextInput value={uid} editable={false} style={{ opacity: 0.5 }} />
        <TextInput
          value={user.phone}
          editable={false}
          style={{ opacity: 0.5 }}
        />
        <Pressable
          style={{
            backgroundColor: colors.accent,
            padding: 10,
            marginTop: 20,
            borderRadius: 30,
            opacity:
              !email || !firstName || !lastName || ajax || uploading ? 0.5 : 1,
          }}
          onPress={() => _save()}
          disabled={!email || !firstName || !lastName || ajax || uploading}
        >
          {ajax ? (
            <ActivityIndicator />
          ) : (
            <Text
              style={{
                color: "#FFF",
                textAlign: "center",
                fontSize: 20,
              }}
            >
              SAVE
            </Text>
          )}
        </Pressable>
        <Updates />
      </ScrollView>
    </KeyboardAvoidingView>
  );
}
