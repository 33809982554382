import React, { useState, useEffect } from "react";
import {
  View,
  Modal,
  Pressable,
  KeyboardAvoidingView,
  ScrollView,
  Image,
  Dimensions,
} from "react-native";
import Text from "../components/Text";
import TextInput from "../components/TextInput";
import { useColors } from "../store";
import { Video } from "expo-av";

export default function ImagesPreSend({ images, close, send }) {
  const { colors } = useColors();
  const [slide, setSlide] = useState(0);
  const [descriptions, setDescriptions] = useState([]);

  useEffect(() => {
    setSlide(0);
    setDescriptions([]);
  }, [images]);

  return (
    <Modal
      visible={images.length ? true : false}
      presentationStyle={"formSheet"}
      animationType={"slide"}
    >
      <KeyboardAvoidingView
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#222",
        }}
        behavior={"padding"}
        keyboardVerticalOffset={60}
      >
        <View style={{ flexDirection: "row", padding: 10 }}>
          <Pressable onPress={() => close()} style={{ padding: 10 }}>
            <Text style={{ color: "#FFF", fontSize: 16 }}>Cancel</Text>
          </Pressable>
          <Text
            style={{
              flex: 1,
              textAlign: "center",
              color: "#FFF",
              padding: 10,
              fontSize: 16,
            }}
          >
            {slide + 1} of {images.length}
          </Text>
          <Pressable
            onPress={() => send(descriptions)}
            style={{ paddingVertical: 10, paddingHorizontal: 7 }}
          >
            <Text
              style={{
                color: colors.main,
                fontFamily: "Lato_700Bold",
                fontSize: 16,
              }}
            >
              Send All
            </Text>
          </Pressable>
        </View>
        <ScrollView
          style={{ flex: 1 }}
          horizontal
          pagingEnabled
          showsHorizontalScrollIndicator={false}
          onMomentumScrollEnd={(e) =>
            setSlide(
              Math.round(
                e.nativeEvent.contentOffset.x / Dimensions.get("screen").width
              )
            )
          }
        >
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <View style={{ flexDirection: "row" }}>
              {images.map((i) => (
                <React.Fragment key={i.path}>
                  {i.mime.startsWith("image") ? (
                    <Image
                      source={{ uri: i.path }}
                      style={{
                        width: Dimensions.get("screen").width,
                        height: Dimensions.get("screen").height - 200,
                      }}
                      resizeMode={"contain"}
                    />
                  ) : (
                    <Video
                      source={{
                        uri: i.path,
                      }}
                      style={{
                        width: Dimensions.get("screen").width,
                        height: Dimensions.get("screen").height - 200,
                      }}
                      shouldPlay={true}
                    />
                  )}
                </React.Fragment>
              ))}
            </View>
          </View>
        </ScrollView>
        <View style={{ padding: 30, flexDirection: "row" }}>
          <TextInput
            style={{ flex: 1, borderRadius: 25, paddingHorizontal: 20 }}
            placeholder="Enter description (optional)"
            value={descriptions[slide]}
            onChangeText={(e) => {
              descriptions[slide] = e;
              setDescriptions([...descriptions]);
            }}
          />
        </View>
      </KeyboardAvoidingView>
    </Modal>
  );
}
