import React, { useState, useEffect } from "react";
import {
  View,
  Pressable,
  FlatList,
  ActivityIndicator,
  TextPropTypes,
} from "react-native";
import { useColors, useContacts } from "../store";
import Text from "../components/Text";
import TextInput from "../components/TextInput";
// import AlphabetList from "react-native-flatlist-alphabet";
// import { AlphabetList } from "react-native-section-alphabet-list";
import Avatar from "../components/Avatar";
import axios from "axios";
import Ionicons from "@expo/vector-icons/Ionicons";

const prepareData = (input, view, q) => {
  let output = [];
  if (input) {
    Object.keys(input).forEach((c) => {
      input[c].key = c;
      input[c].value = input[c].name;
      output.push(input[c]);
    });
  }
  if (q)
    output = output.filter(
      (c) => c.name.toLowerCase().indexOf(q.toLowerCase()) > -1
    );

  output.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

  if (view === "next") return output.filter((c) => c.hasNext);
  if (view === "phoneBook")
    return output.filter((c) => c.source === "phoneBook");
  if (view === "email") return output.filter((c) => c.source === "email");

  return output;
};

export default function ContactList({
  onSelect,
  groupChat,
  startGroupChat,
  groupSelected,
  hide,
}) {
  const { contacts } = useContacts();
  const [view, setView] = useState("next");
  const { colors } = useColors();
  const [q, setQ] = useState("");
  const [data, setData] = useState(prepareData(contacts, "next"));
  const [ajax, setAjax] = useState(false);
  // console.log(contacts);

  useEffect(() => {
    setAjax(false);

    if (view === "email") setData([]);
    else setData(prepareData(contacts, view, q));
  }, [contacts, view, q]);

  const findByEmail = () => {
    if (q) {
      setAjax(true);
      setData([]);
      axios
        .post("findByEmail", { q })
        .then((d) => {
          setAjax(false);
          d.data.results.forEach((c) => {
            c.key = c._id;
            c.name = c.firstName + " " + c.lastName;
            c.value = c.name;
            c.source = "email";
            c.addEmail = true;
            c.hasNext = true;
          });
          setData(d.data.results);
          // console.log(d.data);
        })
        .catch((e) => {
          setAjax(false);
        });
    }
  };

  return (
    <View style={{ flex: 1 }}>
      {!groupChat ? (
        <View
          style={{
            flexDirection: "row",
            backgroundColor: colors.border,
            alignSelf: "center",
            borderRadius: 5,
            padding: 2,
            minWidth: 320,
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <Pressable
            style={{
              padding: 7,
              backgroundColor: view === "next" ? colors.main : "transparent",
              borderRadius: 5,
              flex: 1,
            }}
            onPress={() => setView("next")}
          >
            <Text
              style={{
                textAlign: "center",
                color: view === "next" ? "#FFF" : colors.text,
              }}
            >
              On Next.chat
            </Text>
          </Pressable>

          <Pressable
            style={{
              padding: 7,
              backgroundColor:
                view === "phoneBook" ? colors.main : "transparent",
              borderRadius: 5,
              flex: 1,
            }}
            onPress={() => setView("phoneBook")}
          >
            <Text
              style={{
                textAlign: "center",
                color: view === "phoneBook" ? "#FFF" : colors.text,
              }}
            >
              Phone Book
            </Text>
          </Pressable>
          <Pressable
            style={{
              padding: 7,
              backgroundColor: view === "email" ? colors.main : "transparent",
              borderRadius: 5,
              flex: 1,
            }}
            onPress={() => {
              setView("email");
              setQ("");
            }}
          >
            <Text
              style={{
                textAlign: "center",
                color: view === "email" ? "#FFF" : colors.text,
              }}
            >
              Email
            </Text>
          </Pressable>
        </View>
      ) : (
        <Text
          style={{ textAlign: "center", marginBottom: 10, color: colors.text }}
        >
          Select contacts for group chat
        </Text>
      )}
      <View style={{ paddingHorizontal: 10 }}>
        {view === "next" && !groupChat ? (
          <Pressable
            style={{ color: colors.text, paddingVertical: 5 }}
            onPress={() => startGroupChat()}
          >
            <Text
              style={{
                textAlign: "center",
                color: colors.main,
                marginBottom: 7,
              }}
            >
              Start a group chat?
            </Text>
          </Pressable>
        ) : null}
        <View style={{ flexDirection: "row" }}>
          <TextInput
            style={{ flex: 1, opacity: ajax ? 0.5 : 1 }}
            placeholder={view === "email" ? "Enter email" : "Search..."}
            onChangeText={(e) => setQ(e)}
            value={q}
            autoCapitalize={"none"}
            keyboardType={view === "email" ? "email-address" : "default"}
            editable={!ajax}
          />
          {view === "email" ? (
            <Pressable
              style={{
                backgroundColor: colors.main,
                justifyContent: "center",
                paddingHorizontal: 14,
                marginLeft: 10,
                borderRadius: 5,
                opacity: ajax ? 0.5 : 1,
              }}
              disabled={ajax}
              onPress={() => findByEmail()}
            >
              <Text style={{ color: "#FFF", fontFamily: "Lato_700Bold" }}>
                FIND
              </Text>
            </Pressable>
          ) : null}
        </View>
        {view === "email" ? (
          <Text style={{ margin: 5, textAlign: "center", opacity: 0.5 }}>
            You can find people by email and chat with them, if they are already
            on Next.chat app.
          </Text>
        ) : null}
        {ajax && <ActivityIndicator style={{ margin: 10 }} />}
      </View>
      <FlatList
        data={data}
        renderItem={({ item }) => (
          <>
            {hide && hide.includes(item._id) ? null : (
              <Pressable
                style={{
                  borderBottomColor: colors.border,
                  borderBottomWidth: 1,
                  padding: 10,
                  flexDirection: "row",
                  alignItems: "center",
                }}
                onPress={() => onSelect(item)}
              >
                <Avatar url={item.image} size={50} />
                <View style={{ marginLeft: 10 }}>
                  <Text
                    style={{
                      fontFamily: "Lato_700Bold",
                      marginBottom: 3,
                      fontSize: 16,
                    }}
                  >
                    {item.value}
                  </Text>
                  <View style={{ flexDirection: "row" }}>
                    {item.source === "email" ? (
                      <Text style={{ opacity: 0.7 }}>{item.email}</Text>
                    ) : null}
                    {item.source === "phoneBook" ? (
                      <Text style={{ opacity: 0.7 }}>{item.phone}</Text>
                    ) : null}
                    {item.hasNext ? (
                      <Text style={{ marginLeft: 10, opacity: 0.5 }}>
                        On Next.chat
                      </Text>
                    ) : null}
                  </View>
                </View>
                {groupChat ? (
                  <View
                    style={{
                      flex: 1,
                      alignItems: "flex-end",
                      paddingRight: 10,
                    }}
                  >
                    {groupSelected[item._id] ? (
                      <Ionicons
                        name={"checkmark-circle"}
                        size={26}
                        color={colors.main}
                      />
                    ) : (
                      <View
                        style={{
                          width: 22,
                          height: 22,
                          borderColor: colors.main,
                          borderWidth: 1,
                          borderRadius: 13,
                          marginRight: 3,
                        }}
                      />
                    )}
                  </View>
                ) : null}
              </Pressable>
            )}
          </>
        )}
      />
      {/* <AlphabetList
        data={prepareData(contacts)}
        indexLetterStyle={{
          color: "blue",
          fontSize: 15,
        }}
        renderCustomItem={(item) => (
          <Pressable
            style={{
              borderBottomColor: colors.border,
              borderBottomWidth: 1,
              padding: 10,
              flexDirection: "row",
              alignItems: "center",
            }}
            onPress={() => onSelect(item)}
          >
            <Avatar url={item.image} size={50} />
            <View style={{ marginLeft: 10 }}>
              <Text
                style={{ fontFamily: "Lato_700Bold", marginBottom: 3, fontSize: 16 }}
              >
                {item.value}
              </Text>
              <View style={{ flexDirection: "row" }}>
                {item.source === "email" ? (
                  <Text style={{ opacity: 0.7 }}>{item.email}</Text>
                ) : null}
                {item.source === "phoneBook" ? (
                  <Text style={{ opacity: 0.7 }}>{item.phone}</Text>
                ) : null}
                {item.hasNext ? (
                  <Text style={{ marginLeft: 10, opacity: 0.5 }}>
                    On Next.chat
                  </Text>
                ) : null}
              </View>
            </View>
          </Pressable>
        )}
        renderCustomSectionHeader={(section) => (
          <View style={{ backgroundColor: colors.main, padding: 5 }}>
            <Text style={{ color: "#FFF" }}>{section.title}</Text>
          </View>
        )}
      /> */}

      {/* <AlphabetList
        data={prepareData(contacts)}
        renderItem={(item) => (
          <Pressable
            style={{
              borderBottomColor: colors.border,
              borderBottomWidth: 1,
              padding: 10,
              flexDirection: "row",
              alignItems: "center",
            }}
            onPress={() => onSelect(item)}
          >
            <Avatar url={item.image} size={50} />
            <View style={{ marginLeft: 10 }}>
              <Text
                style={{ fontFamily: "Lato_700Bold", marginBottom: 3, fontSize: 16 }}
              >
                {item.name}
              </Text>
              <View style={{ flexDirection: "row" }}>
                {item.source === "email" ? (
                  <Text style={{ opacity: 0.7 }}>{item.email}</Text>
                ) : null}
                {item.source === "phoneBook" ? (
                  <Text style={{ opacity: 0.7 }}>{item.phone}</Text>
                ) : null}
                {item.hasNext ? (
                  <Text style={{ marginLeft: 10, opacity: 0.5 }}>
                    On Next.chat
                  </Text>
                ) : null}
              </View>
            </View>
          </Pressable>
        )}
      /> */}
    </View>
  );
}
