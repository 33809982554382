import axios from "axios";
import React, { useEffect } from "react";
import useContacts from "../store/Contacts";

export default function PermissionsChecker() {
  const { setContacts } = useContacts();
  useEffect(() => {
    axios.get("contacts").then((d) => {
      // console.log(d.data);
      setContacts(d.data.contacts);
    });
  }, []);
  return null;
}
