import React, { useEffect } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { SwipeListView } from "react-native-swipe-list-view";
import { useCallHistory, useColors, useContacts, useSocket } from "../store";
import Avatar from "../components/Avatar";
import Ionicons from "@expo/vector-icons/Ionicons";
import dayjs from "dayjs";
import { useIsFocused } from "@react-navigation/native";
import { humanTimeFormat } from "./CallScreen";
import { useActionSheet } from "@expo/react-native-action-sheet";

const Row = ({
  uuid,
  lastMessage,
  timestamp,
  onPress,
  unread,
  image,
  blocked,
  colors,
  contacts,
  name,
  hasVideo,
  type,
  duration,
}) => (
  <View
    style={{
      backgroundColor: unread ? colors.unreadBg : colors.white,
    }}
  >
    <TouchableOpacity
      style={{
        padding: 10,
        borderBottomColor: colors.border,
        borderBottomWidth: 1,
        flexDirection: "row",
        alignItems: "center",
      }}
      onPress={() => onPress(uuid, hasVideo)}
    >
      {name ? (
        <>
          <Avatar url={image ? image : ""} size={50} group />
          <View style={{ marginLeft: 10, flex: 1 }}>
            <Text
              style={{
                fontSize: 16,
                color: colors.main,
                fontFamily: unread ? "Lato_900Black" : "Lato_700Bold",
              }}
            >
              {name}
            </Text>
            <Text style={{ opacity: 0.7 }}>
              {type} {hasVideo ? "video call" : "audio call"}{" "}
              {duration ? <Text>({humanTimeFormat(duration)})</Text> : null}
            </Text>
          </View>
        </>
      ) : (
        <>
          <Avatar url={contacts[uuid]?.image} size={50} />
          <View
            style={{
              backgroundColor: colors.main,
              padding: 5,
              borderRadius: 50,
              position: "absolute",
              left: 36,
              top: 34,
              opacity: 0.9,
            }}
          >
            <Ionicons
              name={hasVideo ? "videocam-outline" : "call-outline"}
              size={18}
              color={"#FFF"}
            />
          </View>
          <View style={{ marginLeft: 10, flex: 1 }}>
            <Text
              style={{
                fontSize: 16,
                color: type === "missed" ? colors.red : colors.main,
                fontFamily: unread ? "Lato_900Black" : "Lato_700Bold",
              }}
            >
              {contacts[uuid] ? contacts[uuid].name : "Unknown contact"}
            </Text>
            <Text
              style={{
                opacity: 0.7,
                color: type === "missed" ? colors.red : colors.text,
              }}
            >
              {type} {hasVideo ? "video call" : "audio call"}{" "}
              {duration ? <Text>({humanTimeFormat(duration)})</Text> : null}
            </Text>
          </View>
        </>
      )}
      <View>
        <Text
          style={{
            textAlign: "right",
            fontSize: 12,
            // opacity: 0.5,
            color: type === "missed" ? colors.red : colors.accent,
            fontFamily: "Lato_700Bold",
          }}
        >
          {dayjs(
            (timestamp?.seconds - (duration ? duration : 0)) * 1000
          ).fromNow()}
        </Text>
      </View>
    </TouchableOpacity>
  </View>
);

export default function Calls() {
  const { calls, markAllAsSeen, removeCall } = useCallHistory();
  const { colors } = useColors();
  const { contacts, checkMissing } = useContacts();
  const { call } = useSocket();
  const isFocused = useIsFocused();
  const { showActionSheetWithOptions } = useActionSheet();

  const _onPress = (id) => {
    const options = ["Video call", "Audio call", "Cancel"];

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex: options.length - 1,
      },
      (buttonIndex) => {
        switch (buttonIndex) {
          case 0:
            call(id, true);
            break;
          case 1:
            call(id, false);
            break;
        }
      }
    );
  };

  useEffect(() => {
    let missing = [];
    calls.forEach((v) => {
      if (!contacts[v.uuid] && !missing.includes(v.uuid)) missing.push(v.uuid);
    });
    checkMissing(missing);
  }, [calls]);

  useEffect(() => {
    if (isFocused) markAllAsSeen();
  }, [isFocused]);

  const _removeCall = (id) => {
    // console.log("_removeCall", id);
    removeCall(id);
  };

  if (calls.length)
    return (
      <View style={{ flex: 1 }}>
        <SwipeListView
          data={calls}
          renderItem={(item) => (
            <Row
              {...item.item}
              contacts={contacts}
              colors={colors}
              onPress={(id) => {
                _onPress(id);
              }}
            />
          )}
          keyExtractor={(item) => item._id}
          renderHiddenItem={(data, rowMap) => (
            <View style={{ flex: 1, alignItems: "flex-end" }}>
              <TouchableOpacity
                onPress={() => _removeCall(data.item._id)}
                style={{
                  width: 75,
                  backgroundColor: "#FC4954",
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Ionicons name="trash" size={24} color={"#FFF"} />
              </TouchableOpacity>
            </View>
          )}
          disableRightSwipe
          rightOpenValue={-75}
        />
      </View>
    );

  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <Text>Your call history will appear here.</Text>
    </View>
  );
}
