import create from "zustand";
import "firebase/firestore";
import axios from "axios";
import * as ImageManipulator from "expo-image-manipulator";
import { Platform } from "react-native";

function DataURIToBlob(dataURI) {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
}

const useUploads = create((set, get) => ({
  uploadPercents: {},
  uploadDone: {},
  resizeAndUpload: async (url, id, callback, description) => {
    // console.log(url);
    if (get().uploadDone[url]) {
      // console.log("ALREADY UPLOADED: ", get().uploadDone[url]);
      callback(id, get().uploadDone[url], "image", "", description);
    } else {
      const result = await ImageManipulator.manipulateAsync(
        url,
        [{ resize: { width: 1200 } }],
        { compress: 0.6, format: ImageManipulator.SaveFormat.JPEG }
      );
      const resized = result.uri;
      // console.log("RESIZED", resized);

      set((state) => {
        state.uploadPercents[id] = 0;
        return { ...state };
      });

      const formData = new FormData();
      if (Platform.OS === "web") {
        formData.append("file", DataURIToBlob(resized), "upload.jpg");
      } else {
        formData.append("file", {
          type: "image/jpeg",
          name: "upload.jpg",
          uri:
            Platform.OS === "android"
              ? resized
              : resized.replace("file://", ""),
        });
      }

      axios
        .post("upload", formData, {
          onUploadProgress: (progressEvent) => {
            set((state) => {
              state.uploadPercents[id] = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              return { ...state };
            });

            // console.log(
            //   "PERCENT " + id,
            //   Math.round((progressEvent.loaded * 100) / progressEvent.total)
            // );
          },
        })
        .then((d) => {
          // console.log("UPLOAD DONE " + id, d.data);
          callback(id, d.data.url, "image", "", description);
          set((state) => {
            state.uploadDone[url] = d.data.url;
            delete state.uploadPercents[id];
            // console.log(state);
            return { ...state };
          });
        })
        .catch(() => {
          alert("Error uploading picture.");
        });
    }
  },
  upload: async (url, id, callback, type, filename, description) => {
    if (get().uploadDone[url] && type !== "file") {
      // console.log("ALREADY UPLOADED: ", get().uploadDone[url]);
      callback(id, get().uploadDone[url], type, filename, description);
    } else {
      set((state) => {
        state.uploadPercents[id] = 0;
        return { ...state };
      });

      const formData = new FormData();

      let ext;
      if (Platform.OS === "web") {
        ext = filename.split(".");
        ext = ext[ext.length - 1];
        formData.append("file", DataURIToBlob(url), "upload." + ext);
      } else {
        ext = url.split(".");
        ext = ext[ext.length - 1];
        ext = ext.split("?")[0];
        formData.append("file", {
          name: "upload." + ext,
          uri: Platform.OS === "android" ? url : url.replace("file://", ""),
        });
      }
      console.log(ext);

      axios
        .post("uploadFile", formData, {
          onUploadProgress: (progressEvent) => {
            set((state) => {
              state.uploadPercents[id] = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              return { ...state };
            });

            console.log(
              "PERCENT " + id,
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
          },
        })
        .then((d) => {
          console.log("UPLOAD DONE " + id, d.data);
          callback(id, d.data.url, type, filename, description);
          set((state) => {
            state.uploadDone[url] = d.data.url;
            delete state.uploadPercents[id];
            // console.log(state);
            return { ...state };
          });
        })
        .catch(() => {
          alert("Error uploading file.");
        });
    }
  },
}));

export default useUploads;
