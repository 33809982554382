import create from "zustand";
import firebase from "firebase/app";
import "firebase/firestore";
let unsubscribe;
let uuid;

const useCallHistory = create((set, get) => ({
  calls: [],
  unseen: null,
  subscribeCallHistory: (uid) => {
    // console.log("subscribeCallHistory", uid);
    uuid = uid;
    unsubscribe = firebase
      .firestore()
      .collection("users")
      .doc(uuid)
      .collection("calls")
      .orderBy("timestamp", "desc")
      .limit(20)
      .onSnapshot(async (snap) => {
        let c = [];
        snap.docs.forEach((d) => {
          // console.log("got call", d.data());
          c.push({ ...d.data(), ...{ _id: d.id } });
        });
        set({ calls: c, unseen: c.filter((v) => v.unread).length || null });
      });
  },
  unsubscribeCallHistory: () => {
    // console.log("unsubscribeCallHistory");
    if (unsubscribe) unsubscribe();
  },
  markAllAsSeen: () => {
    const ids = get()
      .calls.filter((v) => v.unread)
      .map((c) => c._id);

    if (ids.length) {
      const batch = firebase.firestore().batch();
      ids.forEach((id) => {
        batch.set(
          firebase
            .firestore()
            .collection("users")
            .doc(uuid)
            .collection("calls")
            .doc(id),
          { unread: false },
          { merge: true }
        );
      });
      setTimeout(() => {
        batch.commit();
      }, 1000);
    }
  },
  removeCall: (id) => {
    firebase
      .firestore()
      .collection("users")
      .doc(uuid)
      .collection("calls")
      .doc(id)
      .delete();
  },
  addCall: (recipient, type, hasVideo, unread) => {
    console.log("addCall", uuid, type, hasVideo);

    const batch = firebase.firestore().batch();

    batch.set(
      firebase
        .firestore()
        .collection("users")
        .doc(recipient)
        .collection("calls")
        .doc(),
      {
        type,
        hasVideo,
        uuid,
        unread: unread ? unread : false,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      }
    );

    batch.set(
      firebase
        .firestore()
        .collection("users")
        .doc(recipient)
        .collection("conversations")
        .doc(uuid),
      {
        lastTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
        lastMessage: type + (hasVideo ? " video call" : " audio call"),
      },
      { merge: true }
    );

    batch.set(
      firebase
        .firestore()
        .collection("users")
        .doc(recipient)
        .collection("conversations")
        .doc(uuid)
        .collection("messages")
        .doc(),
      {
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        system: true,
        sender: uuid,
        text: "call",
        type,
        hasVideo,
      }
    );

    batch.commit();
  },
  addOwnCall: (recipient, type, hasVideo, duration) => {
    console.log("addOwnCall", recipient, type, hasVideo, duration);
    const batch = firebase.firestore().batch();

    batch.set(
      firebase
        .firestore()
        .collection("users")
        .doc(uuid)
        .collection("calls")
        .doc(),
      {
        type,
        hasVideo,
        uuid: recipient,
        unread: false,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        duration,
      }
    );

    batch.set(
      firebase
        .firestore()
        .collection("users")
        .doc(uuid)
        .collection("conversations")
        .doc(recipient),
      {
        lastTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
        lastMessage: type + (hasVideo ? " video call" : " audio call"),
      },
      { merge: true }
    );

    batch.set(
      firebase
        .firestore()
        .collection("users")
        .doc(uuid)
        .collection("conversations")
        .doc(recipient)
        .collection("messages")
        .doc(),
      {
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        system: true,
        sender: recipient,
        text: "call",
        type,
        hasVideo,
        duration,
      }
    );

    batch.commit();
  },
}));

export default useCallHistory;
