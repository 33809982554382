import React, { useEffect } from "react";
import { View, ActivityIndicator, Modal } from "react-native";
import Onboarding from "./onboarding";
import MainTabStack from "./MainTabStack";
import * as SplashScreen from "expo-splash-screen";
import ProfileEdit from "./pages/ProfileEdit";
import PermissionsChecker from "./onboarding/PermissionsChecker";
import { useColors, useUser } from "./store";
import { useColorScheme } from "react-native-appearance";
import { StatusBar } from "expo-status-bar";
import CallScreen from "./pages/CallScreen";

export default function LoginCheck() {
  const { setTheme, colors } = useColors();
  const { user } = useUser();

  const colorScheme = useColorScheme();

  useEffect(() => {
    if (user !== undefined) SplashScreen.hideAsync();
  }, [user]);

  useEffect(() => {
    setTheme(colorScheme);
  }, [colorScheme]);

  if (user === undefined) return <ActivityIndicator style={{ margin: 20 }} />;
  if (user === null)
    return (
      <>
        <StatusBar style="dark" />
        <Onboarding />
      </>
    );

  return (
    <>
      <StatusBar style="light" />
      <MainTabStack />
      <CallScreen />
      <Modal
        visible={!user.complete}
        animationType={"slide"}
        presentationStyle={"pageSheet"}
      >
        <View style={{ flex: 1, backgroundColor: colors.bg }}>
          <View style={{ height: 30 }} />
          <ProfileEdit />
        </View>
      </Modal>
      {user.complete ? <PermissionsChecker /> : null}
    </>
  );
}
