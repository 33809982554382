import React, { useEffect } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
// import { DripsyProvider } from "dripsy"; //dripsy nesto puca na web-u
import LoginCheck from "./src/LoginCheck";
import { AppearanceProvider } from "react-native-appearance";
import { ActionSheetProvider } from "@expo/react-native-action-sheet";
import axios from "axios";
import AppLoading from "expo-app-loading";
import "expo-firestore-offline-persistence";
import { Platform } from "react-native";

import {
  useFonts,
  Lato_400Regular,
  Lato_700Bold,
  Lato_900Black,
  Lato_300Light,
} from "@expo-google-fonts/lato";

if (__DEV__) {
  // import("./ReactotronConfig").then(() => console.log("Reactotron Configured"));
}

axios.defaults.baseURL = "https://next.loudvibe.com/api/";
// axios.defaults.baseURL = "http://localhost:8080/api/";

//https://themeforest.net/item/qertsa-mobile-app-psd/screenshots/22773598?index=14

export default function App() {
  if (!firebase.apps.length) {
    firebase.initializeApp({
      apiKey: "AIzaSyBtfqMYEXcnmhsHD8-D5IOS68rsblyB_Pg",
      authDomain: "next-2d87c.firebaseapp.com",
      projectId: "next-2d87c",
      storageBucket: "next-2d87c.appspot.com",
      messagingSenderId: "694052588271",
      appId: "1:694052588271:web:225e79e805eb693aa0691a",
      measurementId: "G-9X7NQWDX1B",
    });
    firebase
      .firestore()
      .settings({ experimentalForceLongPolling: Platform.OS === "android" });
    firebase.firestore().enablePersistence();
  }

  let [fontsLoaded] = useFonts({
    Lato_300Light,
    Lato_400Regular,
    Lato_700Bold,
    Lato_900Black,
  });

  if (!fontsLoaded) {
    return <AppLoading />;
  }

  return (
    <AppearanceProvider>
      <ActionSheetProvider>
        {/* <DripsyProvider> */}
        <LoginCheck />
        {/* </DripsyProvider> */}
      </ActionSheetProvider>
    </AppearanceProvider>
  );
  // return null;
}
