import React from "react";
import { View, Text, Alert, Linking, TouchableOpacity } from "react-native";
import { useColors, useContacts } from "../store";
import * as ExpoUpdates from "expo-updates";
import * as Contacts from "expo-contacts";
var hash = require("object-hash");

export default function Updates() {
  const { colors } = useColors();
  const { parsePhoneBook, uploadContactsProgress } = useContacts();

  const check = () => {
    ExpoUpdates.checkForUpdateAsync()
      .then((d) => {
        if (d.isAvailable) {
          Alert.alert(
            "Update is downloading",
            "Wait a little bit, app will restart."
          );
          ExpoUpdates.fetchUpdateAsync().then((d) => {
            ExpoUpdates.reloadAsync();
          });
        } else Alert.alert("No update found.");
      })
      .catch((e) => {
        Alert.alert("Error", JSON.stringify(e));
        console.log(e);
      });
  };

  const getContacts = async () => {
    Contacts.requestPermissionsAsync()
      .then((d) => {
        if (d.granted) {
          // Contacts.getContactsAsync({
          //   fields: [Contacts.Fields.Name, Contacts.Fields.PhoneNumbers],
          // })
          //   .then((d) => {
          // console.log(d.data);
          // const contacts = [
          //   ...d.data,
          //   ...d.data,
          //   ...d.data,
          //   ...d.data,
          //   ...d.data,
          //   ...d.data,
          //   ...d.data,
          //   ...d.data,
          //   ...d.data,
          //   ...d.data,
          //   ...d.data,
          //   ...d.data,
          //   ...d.data,
          //   ...d.data,
          //   ...d.data,
          //   ...d.data,
          //   ...d.data,
          //   ...d.data,
          //   ...d.data,
          //   ...d.data,
          //   ...d.data,
          // ];
          // console.log(hash(d.data));
          //47c33e71a54c9e35555a8f258fc85f8752cc6dbd
          // console.log(d.data.length);
          parsePhoneBook(true);
          // Alert.alert("Success!", hash(contacts) + " - " + contacts.length);
          // })
          // .catch((e) => {
          //   Alert.alert("Error", JSON.stringify(e));
          //   console.log(e);
          // });
        } else {
          Alert.alert(
            "Not granted",
            "You didn't allow Next.chat to take your contacts. Please go to your phone settings and allow contacts to Next.chat if you want to use this option."
          );
        }
      })
      .catch((e) => {
        Alert.alert("Get Permissions Async", JSON.stringify(e));
      });
  };

  const reportABug = () => {
    Linking.openURL(
      "https://abptechnologies.atlassian.net/servicedesk/customer/portal/33"
    );
  };

  return (
    <View style={{ alignItems: "center" }}>
      <TouchableOpacity
        style={{
          backgroundColor: colors.accent,
          padding: 10,
          marginTop: 20,
          borderRadius: 30,
          width: 200,
        }}
        onPress={() => getContacts()}
      >
        <Text
          style={{
            color: "#FFF",
            textAlign: "center",
            fontSize: 14,
          }}
        >
          Refresh contacts
        </Text>
      </TouchableOpacity>
      {uploadContactsProgress ? (
        <View>
          <Text
            style={{
              color: colors.text,
            }}
          >
            {uploadContactsProgress}
          </Text>
        </View>
      ) : null}
      <TouchableOpacity
        style={{
          backgroundColor: colors.accent,
          padding: 10,
          marginTop: 20,
          borderRadius: 30,
          width: 200,
        }}
        onPress={() => reportABug()}
      >
        <Text
          style={{
            color: "#FFF",
            textAlign: "center",
            fontSize: 14,
          }}
        >
          Report a Bug
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={{
          backgroundColor: colors.accent,
          padding: 10,
          marginTop: 20,
          borderRadius: 30,
          width: 200,
        }}
        onPress={() => check()}
      >
        <Text
          style={{
            color: "#FFF",
            textAlign: "center",
            fontSize: 14,
          }}
        >
          Check for Updates
        </Text>
      </TouchableOpacity>
      <Text
        style={{
          margin: 20,
          textAlign: "center",
          color: colors.text,
          opacity: 0.3,
        }}
      >
        Version: 2021-12-21 10:00
      </Text>
    </View>
  );
}
